

.listing-section {
    background-color: var(--color-white-2);
    padding-top: 30px;
    padding-bottom: 30px;

    @include media('xs') {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include media('sm') {
        padding-bottom: 90px;
    }
    @include media('md') {
        padding-bottom: 120px;
    }
}
