
.article-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--gutter-width);
    grid-row-gap: var(--gutter-width);

    @media (min-width: 640px) {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('md') {
        grid-row-gap: 60px;
        grid-template-columns: repeat(3, 1fr);
    }
}
