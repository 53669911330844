
.contact-form__title {
    font-size: 20px;
    @extend .font-headline-700;
}

.contact-form .form {
    margin-top: 45px;
}

