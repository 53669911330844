@use "sass:map";
// NOTE: This file uses path resolution to transfrom \$fonts/futura into the correct path
// See /vite.config.js for details

@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=0f6b4d3f-bd05-4d78-a379-ae167466fd93");

@font-face {
    font-family: "Futura PT W01 Light";
    src: url("../../fonts/futura/9ad55860-bbe2-4e51-be58-08b45bdda354.woff2") format("woff2"),
        url("../../fonts/futura/eceb6e13-403b-4d2b-af74-c05bc9c2535e.woff") format("woff");
}

@font-face {
    font-family: "Futura PT W01 Book";
    src: url("../../fonts/futura/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"),
        url("../../fonts/futura/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff");
}

@font-face {
    font-family: "Futura PT W01 Medium";
    src: url("../../fonts/futura/f7552419-a773-41ab-ae4a-b12b1d853f02.woff2") format("woff2"),
        url("../../fonts/futura/83e72918-97c3-41cd-8a7d-4056788a00f0.woff") format("woff");
}

@font-face {
    font-family: "Futura PT W01 Demi";
    src: url("../../fonts/futura/848602e3-bda3-4b1c-976b-fe26eecfab31.woff2") format("woff2"),
        url("../../fonts/futura/e4eacf8d-aba1-40d4-9597-1198e91dcf00.woff") format("woff");
}

@font-face {
    font-family: "Futura PT W01 Bold";
    src: url("../../fonts/futura/1a34942e-33ed-43bb-b229-7460d55b49f7.woff2") format("woff2"),
        url("../../fonts/futura/baecea54-cfd3-4578-8717-abf89eba62b6.woff") format("woff");
}
@font-face {
    font-family: "Futura PT W01 Heavy";
    src: url("../../fonts/futura/6650716f-e079-48f1-861d-00f498c16e8e.woff2") format("woff2"),
        url("../../fonts/futura/21fe5b07-01c8-4b82-8e0b-2bf22f6481fa.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Text W01 Regular";
    src: url("../../fonts/futura/3950a20b-f8db-4575-90e1-54949d42f755.woff2") format("woff2"),
        url("../../fonts/futura/8d4ecdec-9501-4858-97da-282b8b5b09b6.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Text W01 Medium";
    src: url("../../fonts/futura/ad3cbc5c-e0d0-4207-9653-61f550fa0987.woff2") format("woff2"),
        url("../../fonts/futura/25a8bdd9-426d-4a2d-a0b8-dfc665673b4b.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Text W01 Bold";
    src: url("../../fonts/futura/db16cfaf-57dc-48e9-a1e3-e0572db15353.woff2") format("woff2"),
        url("../../fonts/futura/2d609a6e-7431-4ef7-9c94-bf235cdbc130.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Headline W01 Regular";
    src: url("../../fonts/futura/91a66b41-dee4-460f-abef-9e1de073c1b9.woff2") format("woff2"),
        url("../../fonts/futura/57e42cef-35a4-4a16-898f-1fd8a4696aa0.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Headline W01 Medium";
    src: url("../../fonts/futura/536a0d73-ffcc-4b15-b33f-728912d347be.woff2") format("woff2"),
        url("../../fonts/futura/e1c7a915-006d-4a14-bd1a-95b4431f95d6.woff") format("woff");
}

@font-face {
    font-family: "Futura Now Headline W01 Bold";
    src: url("../../fonts/futura/c4fc5ddc-5ef8-4a0e-82ac-893ef5d34db0.woff2") format("woff2"),
        url("../../fonts/futura/280db206-723c-4aaa-ac28-ce4591522f1a.woff") format("woff");
}

$fonts: (
    'pt': (
        "name": "Futura PT W01",
        "weights": (
            "Light": "100",
            "Book": "400",
            "Medium": "450",
            "Demi": "500",
            "Heavy": "600",
            "Bold": "700"
        )
    ),
    'text': (
        "name": "Futura Now Text W01",
        "weights": (
            "Regular": "400",
            "Medium": "500",
            "Bold": "700"
        )
    ),
    'headline': (
        "name": "Futura Now Headline W01",
        "weights": (
            "Regular": "400",
            "Medium": "500",
            "Bold": "700"
        )
    )
);

:root {
    @each $family, $font in $fonts {
        $weights: map.get($font, "weights");
        @each $name , $weight in $weights {
            --font-#{$family}-#{$weight}: #{map.get($font, "name")} #{$name};
        }
    }
}

@each $family, $font in $fonts {
    $weights: map.get($font, "weights");
    @each $name , $weight in $weights {
        .font-#{$family}-#{$weight} {
            font-family: var(--font-#{$family}-#{$weight});
        }
    }
}

