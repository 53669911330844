
#checkout-shipping-method-load {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter-width);
    margin-bottom: var(--gutter-width);

    @media (min-width: 640px) {
        grid-template-columns: 1fr 1fr;
    }
}

.shipping-method-item {
    @extend .themed-radio;
}


.field__select-store {
    margin-bottom: var(--gutter-width);

    label {
        display: block;
        margin-bottom: 8px;

        &:after {
            content: '*';
            color: #e02b27;
            font-size: 1.2rem;
            margin: 0 0 0 5px;
        }
    }
}
