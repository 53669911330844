@use 'sass:math';

:root {
    --gutter-width: 16px;
    --gutter-half-width: calc(var(--gutter-width) / 2);
    --gutter-compensation: calc(var(--gutter-half-width) * -1);

    @include media('sm') {
        --gutter-width: #{$gutter-width};
    }
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: var(--gutter-compensation) !important;
    margin-left: var(--gutter-compensation) !important;
}

@for $i from 1 through $column-count {

    .col-#{$i} {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: math.div($i * 100, 12) * 1%;
        max-width: math.div($i * 100, 12) * 1%;
        padding-right: var(--gutter-half-width);
        padding-left: var(--gutter-half-width);
    }

    @if $i < 12 {
        .col-offset-#{$i} {
            box-sizing: border-box;
            margin-left: math.div($i * 100, 12) * 1%;
        }
    }
}


// Have to loop sizes first so the cascade follows
// xs, sm, md, lg, xl
// otherwise we get an incorrect order that doesn't allow class names to overwrite
@each $name, $size in $grid-sizes {
    @for $i from 1 through $column-count {
        @media (min-width: $size + 'px') {

            .col-#{$name}-#{$i} {
                box-sizing: border-box;
                flex-grow: 1;
                flex-basis: math.div($i * 100, 12) * 1%;
                max-width: math.div($i * 100, 12) * 1%;
                padding-right: var(--gutter-half-width);
                padding-left: var(--gutter-half-width);
            }

            @if $i < 12 {
                .col-#{$name}-offset-#{$i} {
                    box-sizing: border-box;
                    margin-left: math.div($i * 100, 12) * 1%;
                }
            }
        }
    }

    .col-#{$name}-offset-0 {
        margin-left: unset;
    }
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--gutter-width) !important;
    padding-right: var(--gutter-width) !important;
    flex-grow: 1;
}

@each $breakpoint, $padding in $outer-margins {
    @media (min-width: $breakpoint) {
        .container {
            max-width: calc(1480px + #{$padding} * 2);
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

.container-max {
    @extend .container;
    max-width: 1920px;
    padding-left: 0;
    padding-right: 0;
}

.grid {
    display: grid;
}

.grid-3 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--gutter-width);

    @include media('xs') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('md') {
        grid-template-columns: repeat(3, 1fr);
    }
}
