%btn {
    --_background-color: var(--background-color);
    --_border-color: var(--border-color);
    --_color: var(--color);
    --_hover-background-color: var(--hover-background-color);
    --_hover-border-color: var(--hover-border-color, --_background-color);
    --_hover-color: var(--hover-color);
    @extend .font-pt-700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 1px solid var(--_border-color);
    letter-spacing: 0.1em;
    border-radius: 999px;
    padding: 16px 32px 13.62px;
    cursor: pointer;
    background-color: var(--_background-color);
    color: var(--_color);
    font-size: 16px;
    text-wrap: nowrap;
    transition: background-color 100ms ease-in,
                color 100ms ease-in,
                border-color 100ms ease-in;
    &:hover {
        background-color: var(--_hover-background-color);
        color: var(--_hover-color);
        border: 1px solid var(--_hover-border-color);
        text-decoration: none;
    }

    svg {
        color: var(--svg-color, currentColor);
    }
}

body.ajax-loading {
    [class*="btn"] {
        pointer-events: none;
        filter: grayscale(1);
    }
}


label, input, a, button {

    &[class*="btn"] {
        --background-color: var(--color-white-1);
        --border-color: var(--color-brand-2);
        --color: var(--color-brand-2);
        @extend %btn;

        @include media('lg') {
            padding: 22px 32px 20px;
        }
    }

    &.btn-thin {
        padding: 16px 32px 13.62px;
    }

    &.btn-tiny {
        font-size: 14px;
        padding: 12px 26px 10.62px;
    }

    &.btn-white {
        --border-color: var(--color-white-1);
        --background-color: var(--color-white-1);
        --hover-background-color: transparent;
        --hover-color: var(--color-white-1);
        --color: var(--color-black-1);
    }

    &.btn-white-hover-dark {
        --border-color: var(--color-white-1);
        --background-color: var(--color-white-1);
        --hover-background-color: var(--color-black-1);
        --hover-color: var(--color-white-1);
        --color: var(--color-black-1);
    }

    &.btn-grey-1 {
        --border-color: var(--color-grey-1);
        --background-color: var(--color-grey-1);
        --hover-background-color: transparent;
        --hover-color: var(--color-grey-1);
        --hover-border-color: var(--color-grey-1);
        --color: var(--color-white-1);
    }

    &.btn-grey-2 {
        --border-color: var(--color-grey-2);
        --background-color: var(--color-grey-2);
        --hover-background-color: transparent;
        --hover-color: var(--color-grey-2);
        --color: var(--color-white-1);
    }

    &.btn-brand-2 {
        --border-color: var(--color-brand-2);
        --background-color: var(--color-brand-2);
        --hover-background-color: var(--color-brand-2-dark);
        --hover-border-color: var(--color-brand-2-dark);
        --hover-color: var(--color-white-1);
        --color: var(--color-white-1);
    }

    &.btn-outline-white {
        --border-color: var(--color-white-1);
        --background-color: transparent;
        --hover-background-color: var(--color-white-1);
        --hover-color: var(--color-black-1);
        --hover-border-color: var(--color-black-1);
        --color: var(--color-white-1);
    }

    &.btn-outline-black {
        --border-color: var(--color-black-1);
        --background-color: transparent;
        --hover-background-color: var(--color-black-1);
        --hover-color: var(--color-white-1);
        --color: var(--color-black-1);
    }

    &.btn-outline-brand-2 {
        --border-color: var(--color-brand-2);
        --background-color: transparent;
        --hover-background-color: var(--color-brand-2);
        --hover-border-color: var(--color-brand-2);
        --hover-color: var(--color-white-1);
        --color: var(--color-brand-2);
    }

    &.btn-outline-grey-1 {
        --border-color: var(--color-grey-1);
        --background-color: transparent;
        --hover-background-color: var(--color-grey-1);
        --hover-color: var(--color-white-1);
        --hover-border-color: var(--color-grey-1);
        --color: var(--color-grey-1);
    }

    &.btn-outline-chrome-1 {
        --border-color: var(--color-product-chrome-1);
        --background-color: transparent;
        --hover-background-color: var(--color-product-chrome-1);
        --hover-color: var(--color-white-1);
        --hover-border-color: var(--color-product-chrome-1);
        --color: var(--color-product-chrome-1);
    }

    &.btn-icon--filter {
        svg {
            width: 24px;
            margin-right: 1em;
        }
    }

    &.btn-icon--pin {
        svg {
            width: 32px;
            margin-right: 1em;
        }
    }

    &.btn-icon--cart {
        svg {
            width: 24px;
            margin-right: 1em;
        }
    }

    &.btn-icon--user {
        svg {
            width: 18px;
            margin-right: 1em;
        }
    }

    &.underline {
        border: 0;
        padding: 22px 5px 5px;
        border-bottom: 2px solid var(--color-cyan-1);
        border-radius: 0;
    }

    &[disabled] {
        opacity: .1;
        cursor: default;
        pointer-events: none;
    }
}
