
.fancy-hero {
    @extend .banner-hero;
}

.fancy-hero--center
.fancy-hero__container-wrapper .container {
    justify-content: center;

    .fancy-hero__content-wrapper {
        justify-content: center;
    }

    .fancy-hero__content {
        text-align: center;
    }
}

.fancy-hero__container-wrapper {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, .5);
}

.fancy-hero__container-wrapper .container {
    height: 100%;
    display: flex;

    .breadcrumbs-wrapper {
        position: absolute;
        top: var(--gutter-width);
        left: 0;

        a {
            color: var(--color-white-1);
        }
    }
}

.fancy-hero__breadcrumbs {
    position: absolute;
    top: 32px;
    left: 0;
    font-size: 14px;

    a {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--color-white-1);
        transition: color 100ms ease-in;

        &:hover {
            color: var(--color-cyan-1);
        }
    }

    svg {
        width: 16px;
        color: var(--color-cyan-1);
    }
}

.fancy-hero__content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.fancy-hero__content {
    color: var(--color-white-1);
    transform: translateY(calc( -1 * var(--gutter-width)));
}

.fancy-hero__title {
    @extend .banner-hero__title;
}

.fancy-hero__description {
    @extend .banner-hero__description;
}

