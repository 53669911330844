
.new-address-popup 
.action-show-popup {
    @extend %btn;
    @extend .btn-tiny;
    @extend .btn-outline-brand-2;
    margin-top: var(--gutter-width);
}

.checkout-index-index
.modal-popup.new-shipping-address-modal  {

    .form-shipping-address {
        max-width: 100%;
    }

    .field.choice:has(#shipping-save-in-address-book) {
        @extend .themed-radio;
        margin-top: 0;
        grid-column: span 2;
    }

    .fieldset > .field {
        margin-bottom: 0;
        margin-top: 0;
    }

    .field.street {
        grid-column: span 2;
    }

    .form legend.label {
        @extend .font-text-400;
        font-size: unset;
    }

    #shipping-new-address-form {
        grid-template-columns: 1fr;
        display: flex;

        @media (min-width: 480px) {
            display: flex;
        }

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}
