.product-actions {
    form {
        display: flex;
        flex-direction: column;

        .product-price-wrap {
            order: 0;
        }
        .product-cart-actions {
            order: 1;
            padding-top: var(--gutter-width);
            margin-top: var(--gutter-width);
            border-top: 1px solid var(--color-product-chrome-1);
        }

        &:has(.product-options-bottom) {
            .product-price-wrap {
                order: 9;
            }
            .product-options-bottom {
                order: 10;
            }
        }
    }
}

.product-cart-actions {
    display: grid;
    grid-gap: var(--gutter-width);

    @include media('md') {
        grid-auto-flow: column;
    }
}

.product-actions__add-cart {

    @include media('md') {
        grid-column: span 3;
    }

    a, button {
        width: 100%;
        justify-content: center;
    }
}
