
.job-card {
    display: grid;
    grid-gap: var(--gutter-width);
    padding: 32px;
    background-color: var(--color-white-1);
    box-shadow: rgba(100, 100, 111, 0) 0px 7px 29px 0px;
    transition: box-shadow 100ms ease-in;

    @include media('md') {
        grid-auto-flow: column;
    }
}

.job-card:has(.job-card__actions a:hover) {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.job-card__content {
    margin-bottom: 30px;

    @include media('md') {
        margin-bottom: unset;
    }
}

.job-card__titlebar {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
}

.job-card__title {
    @extend .font-headline-700;
    font-size: 20px;
    width: 100%;
    margin-bottom: 1em;

    @include media('sm') {
        width: auto;
        margin-bottom: unset;
    }
}

.job-card__date {
    font-size: 14px;
}

.job-card__description {
    margin-top: 24px;
    line-height: 1.5;
}

.job-card__details {
    display: flex;
    gap: 24px;
    margin-top: 32px;
}

.job-card__location,
.job-card__contract {
    display: flex;
    align-items: center;

    svg {
        width: 32px;
        margin-right: .5em;
        color: var(--color-cyan-1);
    }
}

.job-card__actions {
    display: flex;

    @include media('md') {
        justify-content: flex-end;
        align-items: flex-start;
    }

    a {
        display: flex;
        align-items: center;
        color: inherit;

        &:hover {
            color: var(--color-cyan-1);

            svg {
                translate: 2px 2px;
            }
        }
    }

    a svg {
        width: 16px;
        color: var(--color-cyan-1);
        translate: 0 2px;
        margin-left: .5em;
        transition: translate 100ms ease-in;
    }
}
