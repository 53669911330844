
.layout-sidebar {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gutter-width);

    .sidebar {
        grid-column: span 12;

        @include media('md') {
            margin-bottom: 60px;
            grid-column: span 3;
        }

        @include media('lg') {
            padding-right: 60px;
        }
    }

    .main {
        grid-column: span 12;

        @include media('md') {
            grid-column: span 9;
        }
    }
}
