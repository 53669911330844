#mobile-menu:checked ~ .main .mobile-navigation-header .hamburger,
#mobile-menu:checked ~ .main .mobile-menu .hamburger {
    transform: rotate(45deg);
    padding-top: 0px;
    padding-bottom: 0px;

    span:first-child {
        transform: rotate(90deg) translateX(calc(50% - 2px));
    }

    span:nth-child(2) {
        transform: translateY(-16px);
    }

    span:last-child {
        display: none;
    }
}

.mobile-navigation-wrapper {

    @include media('sm') {
        display: none;
    }
}

.mobile-navigation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: var(--header-height);
    padding: var(--gutter-width);
    border-bottom: 1px solid var(--color-border-primary);

    .main-logo {
        width: calc(var(--header-height) - calc(var(--gutter-width) * 2));
        display: block;
    }
}

.mobile-navigation-header__links {
    display: flex;
    align-items: center;
    grid-gap: calc(var(--gutter-width) * 2);

    a {
        width: 32px;
        height: 32px;
    }

    .minicart-wrapper {
        margin-left: unset !important;
        margin-top: unset;
        float: unset;
    }
}

#mobile-menu {
    position: absolute;
    opacity: 0;
}


.hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32px;
    height: 32px;
    padding-top: 4px;
    padding-bottom: 4px;

    span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--color-text-primary);
    }
}
