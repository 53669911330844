
.feature-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row;
    padding-left: unset;
    padding-right: unset;

    @include media('xs') {
        margin-top: 30px;
        grid-gap: var(--gutter-width);
        padding-left: var(--gutter-width);
        padding-right: var(--gutter-width);
    }

    @include media('sm') {
        margin-top: 60px;
    }

    @include media('lg') {
        margin-top: 90px;
    }

    a {
        grid-column: span 6;
    }

    a:nth-child(n+2) {
        @include media('sm') {
            grid-column: span 3;
        }
    }

    a:nth-child(1),
    a:nth-child(2) {

        @include media('md') {
            grid-column: span 3;
        }
    }

    a:nth-child(n+3) {

        @include media('md') {
            grid-row: 2;
            grid-column: span 2;
        }
    }

}

