
.share-widget {
    display: flex;
}

.share-widget__list {
    display: flex;

    .share-icon {
        margin-left: .5em;
    }
}

.social-url.social-url--copied {
    &:before,
    &:after {
        opacity: 1;
        display: block;
    }
}
.social-url {
    position: relative;

    &:before,
    &:after {
        opacity: 0;
        display: none;
    }

    &:before {
        z-index: 5;
        content: 'Copied to clipboard';
        position: absolute;
        left: 0;
        top: 0;
        translate: 0 calc(-100% - 15px);
        padding: 5px 10px;
        border-radius: 4px;
        background-color: var(--color-brand-2);
        color: var(--color-white-1);
        text-wrap: nowrap;
    }
    &:after {
        z-index: 4;
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        translate: -50% calc(-50% - 15px);
        width: 15px;
        height: 15px;
        background-color: var(--color-brand-2);
        rotate: 45deg;
    }
}

.share-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--color-white-2);
    color: var(--color-grey-2);
    transition: color 100ms ease-in, background 100ms ease-in;

    &:hover {
        background-color: var(--color-cyan-1);
        color: var(--color-white-1);
    }

    svg {
        pointer-events: none;
    }
}

.share-icon--link {
    svg {
        width: 18px;
    }
}
.share-icon--linkedin {
    svg {
        width: 18px;
    }

    &:hover {
        background-color: var(--color-linkedin-1);
        color: var(--color-white-1);
    }
}
.share-icon--twitter {
    svg {
        width: 18px;
    }
    &:hover {
        background-color: var(--color-twitter-1);
        color: var(--color-white-1);
    }
}
.share-icon--facebook {
    svg {
        width: 60%;
    }
    &:hover {
        background-color: var(--color-facebook-1);
        color: var(--color-white-1);
    }
}
.share-icon--mail {
    svg {
        width: 16px;
    }
}
