.checkout-agreement {
    input {
        posiiton: absolute;
        opacity: 0;
    }

    input:checked + .custom-checkbox {

        > div {
            background-color: var(--color-cyan-1);
            border-color: var(--color-cyan-1);
        }

        svg {
            opacity: 1;
            transform: rotate(0);
            color: var(--color-white-1);
        }
    }
}

.custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 100ms ease-in;
    color: var(--color-grey-2);

    &:hover {
        color: var(--color-brand-2);

        div {
            color: var(--color-brand-2);
        }
    }
}

.custom-checkbox div {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1px solid currentColor;
    color: var(--color-grey-1);
    border-radius: 2px;
    transition: color 100ms ease-in,
                border-color 100ms ease-in;

    svg {
        transform: rotate(5deg);
        opacity: 0;
        transition: 100ms transform ease-in, 100ms opacity ease-in;
    }

}

input[type="checkbox"] :has(+ .custom-checkbox),
.custom-checkbox input {
    position: absolute;
    opacity: 0;
}

.custom-checkbox input:checked ~ div, .custom-checkbox.force-checked div {
    background-color: var(--color-cyan-1);
    border-color: var(--color-cyan-1);
}

.custom-checkbox input:checked ~ div svg, .custom-checkbox.force-checked div svg {
    opacity: 1;
    transform: rotate(0);
    color: var(--color-white-1);
}

.custom-checkbox.custom-checkbox--small {
    font-size: 14px;
}

