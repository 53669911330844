/*.shipping-details:before,
.shipping-details > .messages {
    display: none;
}*/

.shipping-details:before {
    display: none;
}

.actions-toolbar-trigger {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--gutter-width);
}

.cart-empty {
    margin-bottom: 90px;

    a {
        color: var(--color-cyan-1);
    }
}

.abs-field-tooltip input, .field .control._with-tooltip input {
    width: 100%;
    margin-right: 0;
}

.shipping-main {
    .fieldset > .field,
    .fieldset > .fields > .field {
        margin: 0;
    }

    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
        @extend .font-text-400;
        font-weight: unset;
    }

    // .field[name="shippingAddress.company"],
    .field.street {
        grid-column: span 2;
    }

    .form legend {
        font-size: 16px;
        // font-weight: unset;
    }
}

.form-shipping-address {
    border: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width);
}

#shipping-new-address-form {
    display: flex;
    flex-direction: column;
    grid-gap: var(--gutter-width);

    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    // Manually re-order the form
    .field:nth-child(1) { order: 1; }
    .field:nth-child(2) { order: 2; }
    .field:nth-child(3) { order: 3; }
    .field:nth-child(4) { order: 5; }
    .field:nth-child(5) { order: 5; }
    .field:nth-child(6) { order: 6; }
    .field:nth-child(7) { order: 7; }
    .field:nth-child(8) { order: 8; }
    .field:nth-child(9) { order: 9; }
    .field:nth-child(10) { order: 4; }

    > * {
        min-width: 0px; // This fixes a grid overflow bug
    }

}

.checkout-login-form {
    margin-bottom: 20px;
    border: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width);

    fieldset, fieldset:last-child {
        margin: 0;
    }

    .checkout-login-password {
        padding-top: var(--gutter-width);
        display: flex;
        flex-direction: row;

        .field {
            flex-grow: 1;
        }

        .email-login {
            margin-left: 20px;
            margin-top: 30px;
        }
    }
}

.step-content {

    .actions-toolbar {
        display: flex;
        justify-content: flex-end;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: var(--gutter-width);

        @media (min-width: 768px) {
            padding-left: unset;
            padding-right: unset;
        }

        .primary {
            display: flex;
            grid-gap: var(--gutter-width);
        }

        .action-cancel {
            @extend .font-headline-700;
            text-transform: uppercase;
            background-color: var(--color-white-1);
            border-radius: 999px;
            border: 1px solid var(--color-brand-2);
            color: var(--color-brand-2);
            cursor: pointer;
            display: inline-flex;
            font-size: 16px;
            justify-content: center;
            letter-spacing: 0.1em;
            padding: 16px 32px 13.62px;
            text-transform: uppercase;
            text-wrap: nowrap;
            transition: background-color 100ms ease-in, color 100ms ease-in, border-color 100ms ease-in;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            height: 50px;

            span {
                translate: 0 -2px;
            }

            &:hover {
                text-decoration: none;
                background-color: var(--color-brand-2);
                border: 1px solid var(--color-brand-2);
                color: var(--color-white-1);
            }
        }

        .action.primary.checkout,
        .action-update {
            @extend .font-headline-700;
            text-transform: uppercase;
            background-color: var(--color-brand-2);
            border-radius: 999px;
            border: 1px solid var(--color-brand-2);
            color: var(--color-white-1);
            cursor: pointer;
            display: inline-flex;
            font-size: 16px !important;
            justify-content: center;
            letter-spacing: 0.1em;
            padding: 16px 32px 13.62px !important;
            text-transform: uppercase;
            text-wrap: nowrap;
            transition: background-color 100ms ease-in, color 100ms ease-in, border-color 100ms ease-in;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            height: 50px;

            &:hover {
                background-color: var(--color-white-1);
                border: 1px solid var(--color-brand-2);
                color: var(--color-brand-2);
            }

            span {
                translate: 0 -2px;
            }
        }

        .action.primary.checkout {
            span {
                translate: 0 -3px;
            }
        }
        .action.primary.checkout.disabled {
            background-color: var(--color-brand-2--disabled);
            border-color: var(--color-brand-2--disabled);
        }
    }
}

.field-tooltip {
    top: 11px;
    right: 20px;
}

// .billing-address-details {
//     button:not(.primary) {
//         @extend .font-headline-700;
//         text-transform: uppercase;
//         background-color: var(--color-white-1);
//         border-radius: 999px;
//         border: 1px solid var(--color-brand-2);
//         color: var(--color-brand-2);
//         cursor: pointer;
//         display: inline-flex;
//         font-size: 16px;
//         justify-content: center;
//         letter-spacing: 0.1em;
//         padding: 16px 32px 13.62px;
//         text-transform: uppercase;
//         text-wrap: nowrap;
//         transition: background-color 100ms ease-in, color 100ms ease-in, border-color 100ms ease-in;
//         margin-top: var(--gutter-width);
//         margin-bottom: 0 !important;
//         margin-left: 0 !important;
//         margin-right: 0 !important;
//         height: 50px;
//
//         span {
//             translate: 0 -2px;
//         }
//
//         &:hover {
//             background-color: var(--color-brand-2);
//             color: var(--color-white-1);
//         }
//     }
// }

.checkout-payment-method .payment-method-content {
    padding: 0;
}

.checkout-payment-method .step-title {
    @extend .font-headline-700;
    font-size: 20px;
}

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
    max-width: 100%;
}

.checkout-payment-method .billing-address-form form fieldset {
    display: flex;
    flex-direction: column;
    grid-gap: var(--gutter-width);

    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .field:nth-child(1) { order: 1; }
    .field:nth-child(2) { order: 2; }
    .field:nth-child(3) { order: 3; }
    .field:nth-child(4) { order: 5; }
    .field:nth-child(5) { order: 5; }
    .field:nth-child(6) { order: 6; }
    .field:nth-child(7) { order: 7; }
    .field:nth-child(8) { order: 8; }
    .field:nth-child(9) { order: 9; }
    .field:nth-child(10) { order: 4; }

    .field.street {
        display: flex;
        grid-column: span 2;
    }
}

// .checkout-payment-method .billing-address-same-as-shipping-block {
//     display: flex;
//     flex-direction: row;
//
//     input {
//         position: absolute;
//         opacity: 0;
//     }
//
//     label {
//         position: relative;
//         display: flex;
//         align-items: center;
//         grid-gap: 12px;
//         margin-bottom: 0;
//
//         &:before {
//             content: '';
//             display: block;
//             width: 18px;
//             height: 18px;
//             border: 1px solid var(--color-product-chrome-1);
//         }
//     }
//
//     &:has(input:checked) label:after {
//         position: absolute;
//         content: '✓';
//         display: block;
//         width: 18px;
//         height: 18px;
//         top: -1px;
//         left: 3px;
//     }
// }
