
.trade-section {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media('sm') {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @include media('sm') {
        margin-top: 64px;
        margin-bottom: 90px;
    }

    @include media('md') {
        margin-top: 64px;
        margin-bottom: 120px;
    }
}
