.address-block-grid {
    display: grid;
    grid-gap: var(--gutter-width);
    margin-top: var(--gutter-width);

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.address-block {
    border: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width);
}

.address-block-actions {
    margin-top: var(--gutter-width);
    display: flex;
    grid-gap: var(--gutter-width);

    .delete {
        color: var(--color-brand-3);
    }
}
