.links-hero-section {
    padding-top: 30px;

    @include media('xs') {
        padding-top: 60px;
    }

    @include media('sm') {
        padding-top: 90px;
    }

    @include media('md') {
        padding-top: 110px;
    }
}

.links-hero {
    padding-bottom: 30px;

    @include media('md') {
        padding-bottom: 50px;
    }
}

.links-hero__title {
    @extend .font-headline-700;
    font-size: 38px;
    color: var(--color-grey-2);
    text-align: center;

    @include media('sm') {
        font-size: 40px;
    }

    @include media('md') {
        font-size: 48px;
    }
}

.links-hero__list {
    display: grid;
    align-items: center;
    grid-gap: 30px; 
    margin-top: 24px;

    @media (min-width: 375px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('xs') {
    }

    @include media('md') {
        grid-template-columns: repeat(4, 1fr);
        min-height: 270px;
    }
}

.links-hero__item {
    @extend .font-headline-700;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-grey-2);
    font-size: 16px;
    text-align: center;

    @include media('sm') {
        font-size: 18px;
    }

    @include media('lg') {
        font-size: 20px;
    }
}

.links-hero__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: .5em;
    border: 1px solid var(--color-white-2);
    color: var(--color-cyan-1);

    @include media('lg') {
        width: 100px;
        height: 100px;
    }

    svg {
        width: 50%;
        max-height: 100%;
        max-width: 100%;
    }
}
