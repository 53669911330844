
.shipping-summary {
    padding: 32px 24px;
    background-color: var(--color-white-2);
}

.shipping-summary__titlebar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        color: inherit;
        font-size: 14px;
    }
}

.shipping-summary__title {
    @extend .font-headline-700;
    font-size: 20px;
}

.shipping-summary__item-list {
    margin-top: 32px;
}

.shipping-summary__item {
    font-size: 14px;
    display: flex;
    grid-gap: 16px;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-product-chrome-1);
    padding-bottom: 16px;

    + .shipping-summary__item {
        margin-top: 16px;
    }
}

.shipping-summary__item-content {
}

.shipping-summary__item .subtotal {
    display: flex;
    flex: 0 0 90px;
    justify-content: flex-end;
}
.shipping-summary__item .price {
    font-size: 14px;
}

.shipping-summary__item-title {
    @extend .font-text-700;
    margin-bottom: 8px;
}

.shipping-summary__item-finish,
.shipping-summary__item-quantity {
    color: var(--color-grey-4);
    line-height: 1.5;
}

.shipping-summary__totals {
    margin-top: 16px;
}

.shipping-summary__total,
.shipping-summary__total-bold {
    display: flex;
    grid-gap: var(--gutter-width);
    justify-content: space-between;

    + .shipping-summary__total,
    + .shipping-summary__total-bold {
        margin-top: 1em;
    }
}

.shipping-summary__total-bold {
    @extend .font-text-700;
}

.shipping-summary-actions {
    margin-top: 30px;

    .custom-checkbox {
        font-size: 14px;
    }

    input {
        width: 100%;
        margin-top: 30px;
    }
}
