
.jobs {
    margin-top: 64px;
}

.jobs-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter-width);
}
