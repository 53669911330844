
.card__content {
    margin-top: var(--gutter-width);
}

.card__title {
    @extend .font-headline-700;
    font-size: 20px;
}

.card__description {
    line-height: 1.5;
    margin-top: 1em;
}

.card__link {
    display: inline-block;
    margin-top: 30px;
}

.card--brand-collection {
    margin-bottom: 30px;

    @include media('sm') {
        margin-bottom: 0px;
    }

    .card__content {
        margin-top: var(--gutter-half-width);

        @include media('sm') {
            margin-top: var(--gutter-width);
        }
    }

    .card__description {
        margin-top: 12px;

        @include media('sm') {
            margin-top: 1em;
        }
    }

    .card__link {
        margin-top: 15px;

        @include media('sm') {
            margin-top: 30px;
        }
    }
}
