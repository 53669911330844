
.icon-link {
    display: flex;
    width: auto;
    height: 24px;
    color: var(--color-grey-1);

    transition: color 100ms ease-in;

    &:hover {
        color: var(--color-brand-2);
    }
}
