
.vendor-card {
    position: relative;
    display: grid;
    gap: 32px;
    padding: 20px;
    background-color: var(--color-white-1);

    @include media('sm') {
        grid-template-columns: 1fr 1fr;
    }

    @include media('md') {
        display: flex;
    }

    @include media('lg') {
        padding: 32px;
    }
}

.vendor-card__content {
    flex-grow: 1;
}

.vendor-card__content-inner {
    display: grid;

    @include media('md') {
        grid-template-columns: 1fr 1fr;
    }
}

.vendor-card__title {
    @extend .font-headline-700;
    font-size: 20px;

    @include media('sm') {
        font-size: 24px;
    }

    @include media('md') {
        font-size: 28px;
    }

    @include media('lg') {
        font-size: 30px;
    }
}

.vendor-card__image {
    position: absolute;
    top: 10px;
    right: 10px;

    img {
        width: 100%;
    }

    @include media('xs') {
    }

    @include media('sm') {
        position: static;
        top: unset;
        right: unset;
        display: block;
        // width: 250px;
    }

    @include media('md') {
        width: 200px;
    }

    @include media('lg') {
        width: 250px;
    }
}

.vendor-card__table {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 27px;
    width: 100%;
    margin-top: 16px;
}

.vendor-card__col {
    display: grid;
    grid-gap: 16px;

    // &:first-child {
    //     .vendor-card__value {
    //         @include media('sm') {
    //             display: none;
    //         }
    //     }
    // }

    // &:last-child {
    //     display: none;

    //     @include media('sm') {
    //         display: grid;
    //     }
    // }

}

.vendor-card__key {
    font-family: var(--font-text-700);
}

.vendor-card__links-wrapper {
    width: 100%;

    @include media('sm') {
        margin-top: 16px;
    }

    @include media('md') {
    }
}

.vendor-card__links {
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));

    @include media('xs') {
        display: block;
    }

    @include media('md') {
        margin-top: unset;
        border-left: 1px solid var(--color-product-chrome-1);
        padding-left: 27px;
    }

    a {
        display: flex;
        align-items: center;
        color: var(--color-cyan-1);
        transition: color 100ms ease-in;

        + a {
            @include media('xs') {
                margin-top: 1em;
            }
        }

        &:hover {
            color: var(--color-brand-2-dark);
        }
    }

    svg {
        width: 24px;
        color: var(--color-brand-2);
        margin-right: 1em;

        @include media('xs') {
            color: var(--color-grey-2);
        }
    }
}

.vendor-card__link-small {
    display: block;

    @include media('xs') {
        display: none;
    }
}

.vendor-card__link-large {
    display: none;

    @include media('xs') {
        display: block;
    }
}

