
// With the :has selector we can count children!
// (input size * 2) + (gutter * input count - 1)
// This means that our field groups will have a set number of columns dependant on the min width defined.
// eg:  count: 3, minWidth: 280
//      This means when there isn't enough room for 280 * 3 + gutter we will fall back to the previous size
@mixin fieldGroupLimiter($count, $minInputSize) {
    $size: ($minInputSize * $count) + ($gutter-width * $count - $gutter-width);
    @container (min-width: #{$size}) {
        .field-group:has(.field:nth-child(#{$count})) {
            grid-template-columns: repeat(#{$count}, 1fr);
        }
    }
}
