
.notice {
    padding: 32px;
    border: 1px solid var(--color-grey-4);
    border-radius: 8px;
    background-color: var(--color-white-2);
}

.notice-titlebar {
    display: flex;
    align-items: center;

    svg {
        width: 34px;
        margin-right: 1em;
        transform: translateY(2px);
    }
}

.notice-title {
    @extend .font-headline-700;
    font-size: 20px;
}

.notice-content {
    margin-top: 1em;
}
