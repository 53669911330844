
.mega-dialog-layout--sidebar {
    display: grid;
    grid-template-columns: 23% 77%;
}

.mega-dialog-sidebar {
    padding: 56px 27px 56px 0;
    border-right: 1px solid var(--color-product-chrome-1);
}

.mega-dialog-sidebar__title {
    @extend .font-pt-500;
    font-size: 18px;
    text-decoration: underline;
}

.mega-dialog-sidebar__links {
    margin-top: 38px;
}

.mega-dialog-sidebar__link {
    @extend .font-headline-700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0;

    svg {
        width: 12px;
        color: var(--color-cyan-1);
    }
}

a.mega-dialog-sidebar__link--active {
    opacity: .3;

    &:hover {
        color: var(--color-text-primary);
        cursor: default;
    }
}
