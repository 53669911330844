
.register-form {
    padding: 30px;
    background-color: var(--color-white-2);

    @include media('sm') {
        padding: 60px;
    }

    @include media('md') {
        padding: 90px;
        padding-top: 60px;
    }
}

.register-form .form {
    margin-top: 32px;
}


.register-form .form-actions button {
    width: 100%;
}
