.checkout-payment-method 
.payment-methods.items {
    margin: 0;
}

.payment-methods 
.payment-group {
    display: grid;
    grid-gap: var(--gutter-width);

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.payment-methods 
.step-title {
    @media (min-width: 640px) {
        grid-column: span 2;
    }
}

.payment-methods 
.payment-method {
    // background: red;
}

.payment-methods 
.payment-method-title {
    @extend .themed-radio;
}
