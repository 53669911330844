.cart {
    margin-top: 30px;
    // margin-bottom: 30px;

    @include media('sm') {
        margin-top: 40px;
        // margin-bottom: 60px;
    }

    @include media('md') {
        margin-top: 40px;
        //margin-bottom: 72px;
    }
}

.cart-header,
.cart-item {
    display: grid;

    @include media('sm') {
        grid-template-columns: 45% 55%;
    }
}

.cart-header__product-properties {
    display: grid;
    grid-template-columns: 30% 35% 15% 20%;
}

.cart-item__product-properties {
    display: grid;
    grid-gap: var(--gutter-width);
    grid-template-columns: 100px 122px 53px auto;

    @include media('sm') {
        grid-gap: unset;
        grid-template-columns: 30% 35% 15% 20%;
    }
}


.cart-header {
    display: none;
    border-bottom: 1px solid var(--color-grey-4);
    padding-bottom: 14px;

    @include media('sm') {
        display: grid;
        // grid-template-columns: 38% 15% 25% 7% 15%;
    }

    @include media('md') {
        // grid-template-columns: 45% 15% 25% 5% 10%;
    }
}

.cart-header__label {
    @extend .font-text-500;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
}


.cart-header__label-item {
    text-align: left;
}

.cart-header__label-price {
    text-align: right;
}

.cart-price .price {
    font-weight: unset;
    font-size: 16px;
}

.cart-item {
    padding: 16px 0;
    border-bottom: 1px solid var(--color-product-chrome-1);
}

.cart-item__product {
    display: grid;
    grid-template-columns: 100px auto;
    grid-gap: var(--gutter-width);
}

.cart-item__product-image {
    width: 100px;

    img {
        width: 100%;
    }
}

.cart-item__product-details {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--gutter-width);
    justify-content: center;
    padding-right: 52px;

    @include media('sm') {
        margin-bottom: unset;
        padding-right: unset;
    }
}

.cart-item__product-details--messages {
    .action.action-gift {
        display: none;
    }

    .fieldset {
        .field {
            margin: 0;

            label {
                color: var(--color-grey-2);
                font-size: 14px;
            }

            textarea {
                min-height: auto;
                height: 70px;
                resize: none;
                font-size: 14px;
            }
        }
    }
}

.cart-item__product-title {
    @extend .font-headline-700;
    color: var(--color-grey-2);
    margin-bottom: 4px;
}

.cart-item__product-option {
    font-size: 14px;
    margin-top: .5em;
    color: var(--color-grey-2);

    @include media('sm') {
        margin-top: usnet;
    }
}

.cart-item__product-finish {
    margin-top: 4px;
}

.cart-item__product-finish {
    font-size: 14px;
}

.cart-header__label-actions {
    opacity: 0;
    @include media('md') {
        display: block;
    }
}

.cart-item__quantity .product-counter__value {
    width: 1em;

    @include media('md') {
        width: 50px;
    }
}

.cart-item__price {
    opacity: 0;

    @include media('sm') {
        opacity: 1;
    }
}

.cart-item__price,
.cart-item__quantity,
.cart-item__actions,
.cart-item__total {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-item__total {
    justify-content: flex-end;
}


.cart-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 60px;

    .block {
        margin-top: unset;
    }
}

.cart-totals {
    border-top: unset;
    padding-top: unset;
    padding-left: 0;
    padding-right: 0;
}

.cart-summaries {
    width: 100%;

    @include media('sm') {
        width: 320px;
    }

    > div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.cart-summary__key {
    @extend .font-headline-700;
}

.cart-summary__disclaimer {
    font-size: 14px;
    color: var(--color-grey-4);
}

.cart-summary__total {
    border-top: 1px solid var(--color-product-chrome-1);
    padding-top: 8px;
    margin-top: 8px;

    .cart-summary__key {
        letter-spacing: 0.1em;
    }
}

// This is on the body while magento is loading
// [aria-busy="false"] {
//     .cart-actions button {
//         pointer-events: all;
//         opacity: 1;
//
//         &[disabled="disabled"] {
//             pointer-events: none;
//             opacity: .1;
//         }
//     }
// }

// .page-notice .custom-checkbox {
//     pointer-events: none;
// }

// [aria-busy="false"] {
//     .page-notice .custom-checkbox {
//         pointer-events: all;
//     }
// }

.cart-actions {
    width: 100%;
    margin-top: 20px;

    button {
        width: 100%;
    }

    button[disabled="disabled"] {
        pointer-events: none;
        cursor: default;
        filter: grayscale(1);
    }

    @include media('sm') {
        width: 320px;
        margin-top: 50px;
    }
}

.cart-item__quantity {
    display: inline-flex;
}

.cart-item__quantity-widget {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.cart-item__remove {
    padding: 0;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: var(--color-brand-3);

    &:hover,
    &:visited {
        color: var(--color-brand-3);
    }
}

