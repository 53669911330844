
.compare-dialog {
    position: fixed;
    z-index: 300;
    bottom: 0;
    padding: 0;
    border: none;
    width: 100%;
    background-color: var(--color-white-2);
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15);
    transform: translateY(100%);

    &[open] {
        animation: 500ms fly-in-up normal forwards;
    }

    &.hide {
        animation: 500ms fly-out-down normal forwards;
    }
}

.compare-dialog-wrapper {
    position: relative;
}

.compare-dialog-content {
    padding: 24px 0;
}

.compare-dialog__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    transform: translate(-50%, 50%);
    cursor: pointer;

    &:hover {
        svg {
            color: var(--color-brand-3);
        }
    }

    svg {
        width: 15px;
        color: var(--color-text-primary);
        transition: color 100ms ease-in;
    }
}

.compare-dialog__titlebar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    > div {
        display: flex;
        align-items: center;
        grid-gap: 10px;
    }

    a {
        color: var(--color-cyan-1);
        font-size: 14px;
    }
}

.compare-dialog__title {
    @extend .font-headline-700;
    font-size: 20px;
    color: var(--color-text-primary);
}

.compare-dialog__options {
    display: grid;
    grid-gap: var(--gutter-width);
    grid-template-columns: auto 150px;
    // grid-auto-flow: column;
    margin-top: 18px;
}

.compare-dialog__items {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.compare-dialog__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.compare-dialog__slot,
.compare-dialog__item {
    min-height: 72px;
    background: var(--color-white-1);
    border-radius: 4px;
}

.compare-dialog__slot {
    border: 1px solid var(--color-product-chrome-1);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.95), inset 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}

.compare-dialog__item {
    position: relative;
    display: grid;
    grid-template-columns: 58px auto;
    grid-gap: 10px;
    padding: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.compare-dialog__item-remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    border-radius: 19px;
    background-color: var(--color-grey-1);
    color: var(--color-white-1);
    transform: translate(50%, -50%);
    cursor: pointer;
    transition: background-color 100ms ease-in;

    &:hover {
        background-color: var(--color-brand-3);
    }

    svg {
        pointer-events: none;
        width: 9px;
    }
}

.compare-dialog__item-image {
    border: 1px solid var(--color-border-primary);
}

.compare-dialog__item-content {
}

.compare-dialog__item-title {
    max-height: 3em;
}

.compare-dialog__item-color {
    max-height: 1.5em;
}

.compare-dialog__item-title,
.compare-dialog__item-color {
    @extend .font-headline-700;
    font-size: 14px;
    overflow: hidden; 
    text-overflow: ellipsis;
}
