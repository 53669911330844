.custom-radio {
    --size: 20px;
    --border-width: 1px;
    // This must be a round number or half pixels will ruin your day
    --inner-size: 14px; 
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-radio div {
    position: relative;
    width: var(--size);
    height: var(--size);
    margin-right: 10px;
    border: var(--border-width) solid currentColor;
    color: var(--color-grey-1);
    border-radius: 2px;
    border-radius: 50%;
    transform: translateY(1px);

    &:before {
        content: "";
        position: absolute;
        top: calc(50% - var(--inner-size) / 2);
        left: calc(50% - var(--inner-size) / 2);
        width: var(--inner-size);
        height: var(--inner-size);
        border-radius: inherit;
    }
}

.custom-radio input {
    position: absolute;
    opacity: 0;
}

.custom-radio input:checked ~ div {
    border-color: var(--color-cyan-1);

    &:before {
        background-color: var(--color-cyan-1);
    }
}
