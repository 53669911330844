
.feature-panels {
    display: grid;
    margin-top: 60px;

    @include media('xs') {
        margin-top: 90px;
    }

    @include media('sm') {
        margin-top: 120px;
    }

    @include media('md') {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 180px;
    }

    @include media('lg') {
        margin-top: 200px;
    }
}

.feature-panels--margin {
    margin-bottom: 30px;

    @include media('sm') {
        margin-bottom: 60px;
    }

    @include media('md') {
        margin-bottom: 90px;
    }

    @include media('lg') {
        margin-bottom: 120px;
    }
}

.feature-panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: center;
    background-size: cover;
    background-color: var(--color-product-gold-2);

    @include media('md') {
        height: 560px;
        padding-top: unset;
        padding-bottom: unset;
    }

    @include media('lg') {
        height: 660px;
    }
}

.feature-panel__backdrop {
    opacity: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    transition: opacity 100ms ease-in;
}
.feature-panel:hover .feature-panel__backdrop {
    opacity: 1;
}

.feature-panel__content {
    position: relative;
    z-index: 20;
    width: 30ch;
    text-align: center;

    @include media('md') {
        width: 42ch;
    }
}

.feature-panel__title {
    @extend .font-headline-700;
    font-size: 40px;
    line-height: 1.1;
    color: inherit;
    margin-bottom: 1em;

    @include media('sm') {
        font-size: 46px;
    }

    @include media('md') {
        font-size: 48px;
    }
}

.feature-panel--dark {
    color: var(--color-black-1);

    &:hover {
        color: var(--color-black-1);
    }
}

.feature-panel--light {
    color: var(--color-white-1);

    &:hover {
        color: var(--color-white-1);
    }
}
