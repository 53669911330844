
.product-config {
    @extend .font-headline-400;
}

.product-config__title {
    @extend .font-headline-700;
}

.product-config__options {
    margin-top: 1em;

    label {
        display: block;
        margin-bottom: 6px;
        @extend .font-headline-400;
    }

    .field {
        margin-top: 0.75em;
    }

    select, input[type="text"] {
        width: 100%;
        padding: 14px .5em 13.62px;
        //border: 1px solid var(--color-grey-2);
        background-color: var(--color-white-1);
    }

    select {
        appearance: none;
    }
}
