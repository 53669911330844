
.layout-base .breadcrumbs-wrapper {
    padding: var(--gutter-width) 0 16px;

    @include media('sm') {
        padding: 32px 0;
    }
}

.layout-base .page-title .breadcrumbs-wrapper {
    padding: 0;
}
