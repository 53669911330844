
.confirmation {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media('sm') {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @include media('md') {
        margin-top: 120px;
        margin-bottom: 120px;
    }
}

.confirmation__title {
    @extend .font-headline-700;
    font-size: 18px;
    margin-top: 1em;

    @include media('sm') {
        font-size: 22px;
    }

    @include media('sm') {
        font-size: 28px;
    }

    @include media('md') {
        font-size: 30px;
    }
}

.confirmation__content {
    font-size: 16px;
    margin-top: .5em;

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        font-size: 20px;
    }
}

.confirmation__actions {
    margin-top: 20px;

    a + a {
        margin-left: 1em;
    }

    @include media('md') {
        margin-top: 32px;
    }
}
