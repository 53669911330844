
// Sometimes it's nice to have evenly spaced text that uses a non monospace font.
// this class expects every letter of the word to be wrapped in a span.
.monospace {
    display: flex;
    grid-gap: .11px;

    span {
        min-width: 0.6875em;
        max-width: 0.6875em;
        text-align: center;
    }
}
