.page-layout-home-page {
    .home-content {
        margin-bottom: 30px;

        @include media('sm') {
            margin-bottom: 60px;
        }

        @include media('md') {
            margin-bottom: 90px;
        }

        @include media('lg') {
            margin-bottom: 120px;
        }
    }
}
