
.brand-tile-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gutter-width);

    @include media('xs') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media('sm') {
        grid-gap: 30px;
        grid-template-columns: repeat(4, 1fr);
    }

    @include media('md') {
        grid-gap: 40px;
        grid-template-columns: repeat(5, 1fr);
    }

    @include media('lg') {
        grid-gap: 54px;
    }
}

.brand-tile {
    background: var(--color-white-2);
    border: 1px solid var(--color-border-primary);
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0);
    transition: translate 100ms ease-in, box-shadow 100ms ease-in, border-color 100ms ease-in;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(9 / 22) * 100%;
    }

    > .brand-tile__inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        img {
            max-width: 126px;
        }
    }

    &:hover {
        border-color: var(--color-brand-1);
        translate: 0 -5px;
        box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
}

a.brand-tile {
    @extend .font-headline-700;
    font-size: 16px;
    color: var(--color-text-primary);

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        font-size: 20px;
    }
}
