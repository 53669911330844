
.stepwise {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px auto;
    overflow: hidden;
    padding-bottom: 2px;

    &:before {
        position: absolute;
        z-index: 10;
        content: '';
        height: 1px;
        width: 100%;
        background-color: var(--color-product-chrome-1);
        top: 16px;
    }

    @include media('sm') {
        margin: 60px auto;
        width: 80%;
    }

    @include media('lg') {
        width: 910px;
    }
}

.stepwise-step {
    positon: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
        &:before {
            position: absolute;
            content: '';
            z-index: 15;
            top: 16px;
            left: 0;
            width: 50px;
            height: 1px;
            background: var(--color-white-1);
        }
    }
    &:last-child {
        &:before {
            position: absolute;
            content: '';
            z-index: 15;
            top: 16px;
            right: 0;
            width: 50px;
            height: 1px;
            background: var(--color-white-1);
        }
    }
}

.stepwise-step__number {
    @extend .font-headline-700;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--color-white-1);
    border: 1px solid var(--color-product-chrome-1);
    color: var(--color-grey-2);
    border-radius: 32px;
    box-shadow: 0px 0px 0px 8px var(--color-white-1);

    span {
        transform: translateY(-8%);
    }
}

.stepwise-step__label {
    color: var(--color-grey-2);
    margin-top: .5em;
    font-size: 14px;
}

.stepwise-step--active {
    .stepwise-step__number {
        border-color: var(--color-cyan-1);
        background-color: var(--color-cyan-1);
        color: var(--color-white-1);
    }
}

.stepwise-step--complete {
    .stepwise-step__number {
        border-color: var(--color-white-2);
        background-color: var(--color-white-2);
        color: var(--color-grey-2);
    }
}
