.hero-slider-wrapper {
    max-height: 400px;
    overflow: hidden;
    --control-size: 28px;

    @include media('xs') {
        --control-size: 32px;
        max-height: 500px;
    }

    @include media('sm') {
        --control-size: 64px;
        max-height: 600px;
    }

    @include media('md') {
        max-height: 700px;
    }

    @include media('lg') {
        max-height: 750px;
    }
}

.hero-slide {
    position: relative;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    @include media('xs') {
        height: 500px;
    }

    @include media('sm') {
        height: 600px;
    }

    @include media('md') {
        height: 700px;
    }

    @include media('lg') {
        height: 750px;
    }
}

.hero-slide__content-wrapper {
    position: absolute;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: calc(var(--control-size) * 2);
    padding-right: calc(var(--control-size) * 2);

    @include media('sm') {
        padding-left: unset;
        padding-right: unset;
    }
}

.hero-slide__content {
    max-width: 700px;
    text-align: center;
    color: var(--color-white-1);
}

.hero-slide__title {
    @extend .font-pt-400;
    font-size: 36px;
    line-height: 0.9;
    margin: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    @include media('xs') {
        font-size: 46px;
    }

    @include media('sm') {
        font-size: 56px;
    }

    @include media('md') {
        font-size: 76px;
    }

    @include media('lg') {
        font-size: 96px;
    }
}

.hero-slide__description {
    @extend .font-text-400;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 1em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        font-size: 20px;
    }
}

.hero-slide__actions {
    margin-top: 30px;

    @include media('sm') {
        margin-top: 40px;
    }

    @include media('md') {
        margin-top: 64px;
    }
}
