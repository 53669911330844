
.forward-breadcrumbs {
    display: flex;
    color: var(--color-grey-2);

    a {
        color: inherit;
    }

    a:not(:last-child)::after {
        content: '/';
        padding-right: .5em;
        padding-left: .5em;
    }
}
