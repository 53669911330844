
.link-showcase {
    height: 290px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: 200% auto;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 30px;

    @include media('xs') {
        height: 455px;
        margin-top: 60px;
    }

    @include media('sm') {
        height: 585px;
        margin-top: 100px;
    }

    @include media('md') {
        background-size: cover;
        margin-top: 120px;
    }
}

.link-showcase__content {
    width: 430px;
    text-align: center;
    margin: 0 auto;
}

.link-showcase__prefix {
    @extend .font-pt-400;
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.link-showcase__title {
    @extend .font-headline-500;
    margin: 0;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 30px;

    @include media('xs') {
        font-size: 50px;
    }

    @include media('sm') {
        font-size: 60px;
    }

    @include media('md') {
        font-size: 70px;
    }

    @include media('lg') {
        margin-top: 10px;
        margin-bottom: 64px;
        font-size: 80px;
    }
}

