/** Important:
 * select elements must be wrapped with a .select class element
 * with an svg after the select for this to work as expected
 *
 * example:
 * <div class="select">
 *     <select>
 *         <option>Please select</option>
 *     </select>
 *     <include src="./icons/chevron-down.svg"></include>
 * </div>
 */

.select {
    position: relative;
}

.select svg {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%) scaleY(1);
    pointer-events: none;
    transition: transform 100ms ease-in, color 50ms ease-in;
}

.select select, .text-field input {
    width: 100%;
    background-color: var(--color-white-1);
    background: transparent;
    appearance: none;
    user-select: none;
    border: 1px solid  var(--color-grey-3);
    height: 48px;
    padding: 0 1em;
    padding-right: 2.5em;
    outline: none;
    color: var(--color-grey-2);

    &:active {
        border-color: var(--color-brand-2);
    }
}

.select--discrete {
    svg {
        color: var(--color-brand-2);
    }
}
.select--discrete select {
    border: none;
}

.select:has(select:disabled) {
    svg {
        opacity: 0.3;
    }
}

.select:has(select:active) {
    svg {
        transform: translateY(-50%) scaleY(-1);
        color: var(--color-cyan-1);
    }
}

.swatch-attribute-options:has(select) {
    @extend .select;
}

