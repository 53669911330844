
.wishlist-items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;

    @include media('xs') {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 60px;
    }

    @include media('sm') {
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 90px;
    }

    @include media('md') {
        margin-bottom: 120px;
    }
}

