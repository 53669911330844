
.info-feature {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gutter-width);
    padding-top: 90px;
    padding-bottom: 90px;

    @include media('sm') {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.info-feature__content {
    grid-column: span 12;
    grid-row: 2;
    text-align: center;
    display: flex;
    align-items: center;

    @include media('sm') {
        grid-column: 2 / span 4;
        grid-row: 1;
    }
}

.info-feature__title {
    @extend .font-headline-700;
    font-weight: 700;
    font-size: 40px;
    color: var(--color-grey-1);

    @include media('sm') {
        font-size: 48px;
    }
}

.info-feature__description {
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.38;

    @include media('sm') {
        font-size: 20px;
    }
}

.info-feature__actions {
    margin-top: 2em;
}

.info-feature__image {
    grid-column: 1 / span 12;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media('sm') {
        grid-column: span 7;
    }
}
