.auth-panels {
    display: grid;
    grid-gap: var(--gutter-width);
    margin-top: 30px;

    @include media('md') {
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        margin-top: 60px;
    }
}

.auth-panel {
    // background-color: var(--color-white-2);
    background-size: cover;
    max-width: 605px;
}

.auth-panel--compressed {

    .auth-panel-inner {
        align-items: center;
        justify-content: center;
    }

    .auth-panel__content {
        width: 100%;
        flex-grow: 0;
    }
}

.auth-panel-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 28px;

    @include media('sm') {
        padding: 38px;
    }

    @include media('md') {
        padding: 48px;
    }
}

.auth-panel__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .field-helper {
        margin-top: 1em;
        color: inherit;
    }
}

.auth-panel--dark {
    color: var(--color-white-1);
    background-color: var(--color-grey-4);
    background-image: url('../../placeholders/login-form.png');

    .auth-panel-inner {
        backdrop-filter: blur(10px) brightness(50%);
    }

    .form .field {
        color: inherit;
    }
}

.auth-panel__title {
    @extend .font-headline-700;
    color: inherit;
    font-size: 20px;
}

.auth-panel__content {
    margin-top: 24px;
}
