.product-list-toolbar {
    display: flex;
    flex-direction: column;
    grid-gap: var(--gutter-width);
    width: 100%;
    padding-bottom: var(--gutter-width);

    @media (min-width: 640px) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        flex-direction: unset;
    }
}

.product-list-toolbar__details {
    display: none;
    align-items: center;
    font-size: 14px;

    @include media('md') {
        display: flex;
    }

    @media (min-width: 1024px) {
        grid-column: span 3;
    }
}

.product-list-toolbar__main {
    display: flex;
    flex-direction: column;
    grid-column: span 12;
    grid-gap: var(--gutter-width);
    justify-content: space-between;

    @media (min-width: 640px) {
        flex-wrap: wrap;
        align-items: center;
        flex-direction: unset;
    }

    @media (min-width: 1024px) {
        // display: flex;
        grid-column: span 9;
    }

    @include media('md') {
        justify-content: space-between;
    }

    > * {
        width: 100%;

        @media (min-width: 640px) {
            width: auto;
        }
    }
}

[for="product-mobile-filter"]{
    width: 100%;

    @media (min-width: 640px) {
        width: auto;
    }
}

.product-list-toolbar__tags {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;

    @media (min-width: 1024px) {
        flex: unset;
    }

    .tag {
        font-size: 14px;
        display: flex;
        align-items: baseline;
        background-color: var(--color-white-2);
        color: var(--color-text-primary);
        padding: 6px 10px;
        letter-spacing: 0.025em;
        transition: color 100ms ease-in;

        svg {
            width: 10px;
            margin-left: .5em;
            cursor: pointer;

            &:hover {
                color: var(--color-brand-3);
            }
        }

        a {
            color: var(--color-text-primary);

            &:hover {
                color: var(--color-brand-3);
            }
        }
    }
}

.product-list-toolbar__mobile-filter-trigger {
    @include media('md') {
        display: none;
    }
}

.product-list-toolbar__actions {
    display: flex;
    // flex: 0 0 100%;
    justify-content: space-between;
    align-items: center;

    @include media('md') {
        flex: unset;
        justify-content: unset;
    }
}

.product-list-toolbar__filter .select select {
    margin-left: 0;
    padding-left: 0;

    @include media('md') {
        margin-left: 7px;
        padding-left: 2.5em;
    }
}

.product-list-toolbar__list-types {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
}

/*.product-list-option__checkbox {

    &:checked:nth-of-type(1) ~ .container {

        .product-list-toolbar__option:nth-child(1) {
            opacity: 1;
        }
    }
    &:checked:nth-of-type(2) ~ .container {

        .product-list-toolbar__option:nth-child(2) {
            opacity: 1;
        }
    }
}*/

.product-list-toolbar__option {
    cursor: pointer;
    display: block;
    opacity: .5;
    transition: opacity 100ms ease-in;
    color: var(--color-text-primary);

    &.active {
        opacity: 1;
    }

    &:hover {
        color: var(--color-brand-2);
    }

    svg {
        pointer-events: none;
        width: 24px;
        height: 24px;
        transition: color 100ms ease-in;
    }
}
