

.repeating-datetime-field {
    display: grid;
    align-items: center;
    grid-gap: var(--gutter-width);
    grid-template-columns: 100%;
}

.repeating-datetime-container {
    display: grid;
    grid-gap: var(--gutter-width);

    @media (min-width: 480px) {
        grid-template-columns: calc(40% - var(--gutter-width)) calc(40% - var(--gutter-width)) 20%;
    }

    &:first-of-type .repeating-datetime__remove {
        display: none;
    }

    &:not(:first-of-type) .repeating-datetime__add {
        display: none;
    }
}

.repeating-time-field,
.repeating-date-field {
    input {
        width: 100%;
    }
}

.repeating-date-field,
.repeating-time-field {
    position: relative;

    svg {
        position: absolute;
        right: 1em;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
    }
}

.repeating-time-field {
    position: relative;

    .select:has(select:active) svg {
        transform: translateY(-50%);
        color: unset;
    }
}

.repeating-datetime__add,
.repeating-datetime__remove {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 48px;
    padding: 13px 22px 11px 15px !important;
    font-family: var(--font-text-400);
    color: var(--color-grey-1);
    border: 1px solid var(--color-product-chrome-1);
    border-radius: 0;
    font-size: 14px;

    span {
        text-wrap: nowrap;

        span {
            display: none;
            margin-left: .5em;
        }
    }

    svg {
        flex: 0 0 16px;
        width: 16px;
        color: var(--color-brand-2);
        margin-right: .75em;
        translate: 0 0;
    }

    @media (min-width: 480px) {
        justify-content: center;

        svg {
            margin-right: unset;
        }

        span {
            display: none;
        }
    }

    @media(min-width: 1024px) {
        justify-content: flex-start;

        svg {
            margin-right: .75em;
        }

        span {
            display: flex;
        }
    }

    @media(min-width: 1200px) {
        span span {
            display: flex;
        }
    }
}
