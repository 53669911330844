
.form-newsletter-manage {
    border: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width);

    .fieldset > .legend {
        padding: 0;
        margin: 0;
        border: none;
        font-size: 20px;
        margin-bottom: var(--gutter-width);
    }

    .actions-toolbar {
        margin-top: var(--gutter-width);
    }

    .action.primary {
        @extend %btn;
        @extend .btn-thin;
        @extend .btn-brand-2;
    }
}
