
.billing-address-form {
    padding: var(--gutter-width);
    border: 1px solid var(--color-product-chrome-1);
    margin-top: var(--gutter-width);
}

.billing-address-form .choice.field:has(input[type="checkbox"]) {
    @extend .themed-radio;
    grid-column: span 2;
}
