
.feature-tile {
    position: relative;

    img, picture {
        display: block;
        width: 100%;
    }
}

.feature-tile--dark {
    .feature-tile__img {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            z-index: 15;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-grey-2);
            opacity: 0.3;
            transition: opacity 100ms ease-in;
        }
    }

    &:hover {
        .feature-tile__img:before {
            opacity: 0.1;
        }

        .btn-outline-white {
            --border-color: var(--color-black-1);
            --background-color: var(--color-white-1);
            --color: var(--color-black-1);
        }
    }
}

.feature-tile__content {
    position: absolute;
    top: 0; left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px;
    z-index: 30;

    @include media('md') {
        padding: 48px;
    }

    @include media('lg') {
        padding: 64px;
    }
}

.feature-tile__title {
    @extend .font-headline-700;
    font-size: 32px;
    margin: 0;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);

    @include media('lg') {
        font-size: 40px;
    }
}

// .feature-grid:hover {
//     .feature-tile .feature-tile__content {
//         backdrop-filter: blur(5px) grayscale(50%);
//     }
//
//     .feature-tile:hover .feature-tile__content {
//         backdrop-filter: unset;
//     }
// }
