
.field.choice {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: calc(var(--gutter-width) / 2);

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 4px;
    }

    label {
        margin: 0;
        cursor: pointer;
    }

    input {
        position: absolute;
        opacity: 0;
        top: -1em;
    }

    .field-tooltip {
        margin-left: 0;
        right: 0;
        top: -1px;
    }

    &:has(input:checked) {

        &:before {
            border-color: var(--color-brand-2);
            background-color: var(--color-brand-2);
        }

        &:after {
            position: absolute;
            left: 3px;
            top: -1px;
            content: '✓';
            font-size: 18px;
            color: var(--color-white-1);
        }
    }
}
