

.article-card {
    display: flex;
    flex-direction: column;
    color: var(--color-grey-2);

    a {
        transition: color 100ms ease-in;
        &:hover {
            color: var(--color-brand-2)
        }
    }
}

.article-card__image {
    width: 100%;
    aspect-ratio: 24 / 17;

    a {
        display: flex;
        align-items: center;
        height: 100%;
    }

    span {
        height: 100%;
    }

    .logo-placeholder {
        display: flex;
        align-items: center;
        width: 50%;
        margin: 0 auto;
    }

    svg {
        max-width: 100%;
    }
}

.article-card__meta {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.article-card__content {
    margin-top: 1em;
}

.article-card__details {

    a {
        margin-top: 30px;
    }
}

.article-card__title a {
    @extend .font-headline-700;
    color: var(--color-grey-2);
    display: block;
    margin-top: 16px;
    font-size: 20px;
}

.article-card__description {
    margin-top: .5em;
    line-height: 1.5;
}

.article-card__more a {
    display: block;
    margin-top: .5em;
    color: var(--color-grey-2);

    svg {
        color: var(--color-cyan-1);
        width: 10px;
        transform: translateY(1px);
    }
}
