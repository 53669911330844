.finder {
    --map-height: 855px;
    display: grid;
    grid-gap: var(--gutter-width);
    padding-top: 64px;
    margin-bottom: 30px;

    @include media('sm') {
        margin-bottom: 60px;
        grid-template-columns: repeat(12, 1fr);
    }

    @include media('md') {
        margin-bottom: 90px;
    }
}

.finder .simple-back {
    //position: absolute;
    //top: 32px;
    margin-bottom: 15px;
    transition: color 100ms ease-in;

    &:hover {
        color: var(--color-brand-2);
    }
}

.finder__title {
    @extend .font-headline-700;
    font-size: 28px;
    margin-bottom: 1em;
    text-wrap: balance;

    @include media('sm') {
        font-size: 30px;
    }

    @include media('md') {
        font-size: 40px;
    }
}

.finder-content-indicator {
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 5px;
    translate: 100% 0;
    background-color: var(--color-cyan-1);
    transition: translate 150ms 50ms ease-in;
}

.tag--active {
    .finder-content-wrapper {
        display: none;
    }
}

.finder-content-wrapper {

    .finder-content-titlebar {
        label:last-of-type {
            // box-shadow: inset 0px -5px 0 0px var(--color-cyan-1);
            @extend .font-headline-700;
        }
    }

    &:has( > input:first-of-type:checked) {
        .finder-content-indicator {
            translate: 0 0;
        }
        .finder-content-titlebar {
            label:first-of-type {
                // box-shadow: inset 0px -5px 0 0px var(--color-cyan-1);
                @extend .font-headline-700;
            }
            label:last-of-type {
                box-shadow: unset;
                @extend .font-text-400;
            }
        }

        .finder-content {
            translate: 0 0;
            @include media('sm') {
                translate: -50% 0;
            }
        }

        .finder-list {
            max-height: 0;
            @include media('sm') {
                max-height: 9999px;
            }
        }

        .finder-map {
            max-height: 9999px;
            @include media('sm') {
                max-height: 0;
            }
        }
    }

    .finder-content {
        translate: -50% 0;
    }
}

.finder-content-wrapper {
    overflow: hidden;

    > input {
        position: absolute;
        opacity: 0;
    }
}

.finder-content {
    width: 200%;
    display: grid;
    grid-gap: 1px; // fix map edge bleed on desktop
    grid-template-columns: repeat(2, 1fr);
    transition: translate 150ms ease-out;
}

.finder-map {
    max-height: 0;
}

.finder-list {
    max-height: 9999px;
}

.finder-list input {
    opacity: 0;
    position: absolute;
}

.finder-list:has(input:checked) .finder-region {
    display: none;
}

.finder-list > input:checked + .finder-region {
    display: block;

    .finder-region__title-block {
        flex-grow: 0;

        svg {
            transform: rotate(180deg) translateY(-1px);
            margin-right: .5em;
        }
    }

    .finder-region__title {
        order: 1;
    }

    .finder-region__title-indicator {
        display: block;
    }

    .finder-region__stores {
        opacity: 1;
        max-height: 99999px;
    }
}

.finder-showrooms .finder-sidebar {
    position: relative;
}

.finder-sidebar-inner {
    //height: var(--map-height);
    //overflow-y: scroll;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
        width: 0px;
    }
}

.finder-sidebar__scroll-indicator {
    position: absolute;
    width: 100%;
    height: 30px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    z-index: 50;
    opacity: .5;
    transition: opacity 100ms ease-out;

    display: none;//disabled

    svg {
        height: 30px;
        animation: 2s infinite bounce;
    }
}

.finder-sidebar {
    @include media('sm') {
        grid-column: 1 / span 5;
    }

    &:hover {
        .finder-sidebar__scroll-indicator {
            opacity: 0;
        }
    }
}

.finder-main {
    display: none;

    @include media('sm') {
        display: block;
        grid-column: 6 / span 7;
    }
}

.finder-sidebar .search-form {
    margin-top: 24px;

    input:focus {
        box-shadow: inset 0 0 0 1px var(--color-cyan-1);
    }
}

.finder-content-titlebar {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    @include media('sm') {
        display: none;
    }
}

.finder-tags {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    margin: 24px 0;
}

.finder-tag {
    @extend .font-headline-700;
    text-wrap: nowrap;
    border-radius: 99px;
    display: flex;
    align-items: center;
    background-color: var(--color-white-2);
    padding-top: .5em;
    padding-bottom: .75em;
    padding-left: 1em;
    padding-right: 1em;
    cursor: pointer;

    svg {
        width: 24px;
        transform: translateY(2px);
        margin-right: .25em;
    }
}

.finder-tag--active {
    background-color: var(--color-grey-1);
    color: var(--color-white-1);
}

.finder-region__titlebar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-product-chrome-1);
    padding: .8em 0 .8em;
}

.finder-region__title-block {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    svg {
        transform: translateY(1px);
    }
}

.finder-region__title-indicator {
    display: none;
}

.finder-region__title {
    @extend .font-headline-700;
    font-size: 20px;
}

.finder-region__titlebar svg {
    width: 24px;
    color: var(--color-cyan-1);
}

.finder--region-active {
    .finder-results {
        display: none;
    }
}

.message.info.finder-results-message,
.message.info.finder-region__results-message {
    display: none;
    margin-bottom: 1em;
}

.finder.finder-results--empty {
    .map-canvas {
        filter: grayscale(1);
    }
}

.finder-results--none {
    .message.info.finder-results-message,
    .message.info.finder-region__results-message {
        display: block;
        margin-bottom: 1em;
    }
}

.finder-region--searching {
    .finder-region__results {
        display: block;
    }

    .finder-region__stores {
        display: none;
    }
}

.finder-region__stores {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
    padding: 0 !important;
}

.gm-style-iw.gm-style-iw-c {
    .finder-store {
        padding: var(--gutter-width);
    }
}

.finder-store {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em 1em 1em 0;
    border-top: 1px solid var(--color-product-chrome-1);
    grid-gap: var(--gutter-width);

    .link {
        text-wrap: nowrap;
        font-size: 14px;
    }
}

.finder-store__details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.finder-store__content {
    width: 70%;

    p:not(.finder-store__title) a {
        color: var(--color-brand-2);
    }

    p + p {
        margin-top: 1em;
    }
}

.finder-store__title {
    @extend .font-headline-700;
    font-size: 16px;

    a {
        color: var(--color-text-primary);
        transition: color 100ms ease-in;
    }

    a:hover {
        color: var(--color-brand-2);
    }
}

.finder-store__phone,
.finder-store__mail {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-cyan-1);

    svg {
        width: 24px;
        margin-right: 1em;
        color: var(--color-grey-1);
    }
}

.finder-store__address {
    font-size: 14px;
}

.finder-store__icon {
    position: absolute;
    top: var(--gutter-width);
    right: var(--gutter-width);

    svg {
        width: 32px;
    }
}

.map-canvas {
    width: 100%;
    height: var(--map-height);
}

.map-wrapper {
    @include media('sm') {
        position: sticky;
        top:110px;
    }
}
