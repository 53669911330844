.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gutter-width);
    grid-row-gap: calc(var(--gutter-width) * 2);

    &.list {
        grid-template-columns: 1fr;
        grid-gap: var(--gutter-width);
        grid-row-gap: calc(var(--gutter-width) * 2);
    }

    &.grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: var(--gutter-width);
        grid-row-gap: calc(var(--gutter-width) * 2);

        @include media('xs') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('sm') {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

/*// List view
.product-list-option__checkbox:nth-of-type(1):checked ~ .container {
    .product-grid {
        grid-template-columns: 1fr;
        grid-gap: var(--gutter-width);
        grid-row-gap: calc(var(--gutter-width) * 2);
    }
}

// Grid view
.product-list-option__checkbox:nth-of-type(2):checked ~ .container {
    .product-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: var(--gutter-width);
        grid-row-gap: calc(var(--gutter-width) * 2);

        @include media('xs') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('sm') {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}*/

