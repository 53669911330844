
.basic-table {
}

.basic-table__row {
    display: grid;
    grid-template-columns: calc(40% - var(--gutter-half-width)) calc(60% - var(--gutter-half-width));
    grid-gap: var(--gutter-width);
    padding: 5px 0;
}

.basic-table__key {
    @extend .font-pt-700;
}

