/*.layout-stepwise {
    position: relative;
}

.layout-stepwise:before {
    content: '';
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
}*/


.cart--out-of-sync {
    .cart.main.actions {
        display: flex;
    }
    .cart-footer {
        display: none;
    }
}

.cart.main.actions {
    display: none;
    justify-content: flex-end;
    margin-bottom: 60px;
}

.checkout-cart-index .cart-actions {
    button {
        pointer-events: none;
        filter: grayscale(1);
        opacity: .1;
    }
}

.page-notice .custom-checkbox {
    pointer-events: none;
    opacity: .5;
}

[aria-busy="false"]  {
    &.checkout-cart-index .cart-actions button:not([disabled=disabled]) {
        pointer-events: all;
        opacity: 1;
        filter: unset;
    }

    .page-notice .custom-checkbox {
        pointer-events: all;
        opacity: 1;
    }
}

.page-notice {
    position: relative;
    z-index: 105;
    display: grid;
    grid-gap: 16px;
    background-color: var(--color-white-2);
    border: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width);
    border-radius: 8px;
    margin-top: var(--gutter-width);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

    .custom-checkbox {
        margin-top: 1em;
    }

    @media (min-width: 640px) {
        grid-template-columns: 32px auto;
    }
}

.page-notice__content {

    p {
        display: flex;
        grid-gap: 14px;
        flex-direction: column;

        @media (min-width: 640px) {
            display: block;
            grid-gap: unset;
            flex-direction: unset;
        }
    }

    .page-notice-lead {
        display: flex;
        align-items: center;
        grid-gap: var(--gutter-width);
        font-size: 26px;

        @media (min-width: 640px) {
            font-size: unset;
            display: unset;
            align-items: unset;
        }

        svg {
            width: 32px;
            color: var(--color-cyan-1);

            @media (min-width: 640px) {
                display: none;
            }
        }
    }

}

.page-notice__icon {
    display: none;
    width: 32px;
    color: var(--color-cyan-1);

    @media (min-width: 640px) {
        display: block;
    }
}

#page-notice {
    position: absolute;
    opacity: 0;
}

// This is for global checkboxes they have to be name specific
/*#page-notice:checked ~ .main {

    .layout-stepwise:before {
        display: none;
    }

    .page-notice .custom-checkbox {

        div {
            background-color: var(--color-cyan-1);
            border-color: var(--color-cyan-1);
        }

        div svg {
            opacity: 1;
            transform: rotate(0);
            color: var(--color-white-1);
        }
    }
}*/

#checkout-disclaimer {
    visibility: hidden;
    position: absolute;
}

#checkout-disclaimer:checked ~ .custom-checkbox {
    div {
        background-color: var(--color-cyan-1);
        border-color: var(--color-cyan-1);
    }

    div svg {
        opacity: 1;
        transform: rotate(0);
        color: var(--color-white-1);
    }
}
