
.showroom {
    margin-bottom: 30px;
    position: relative;

    @include media('sm') {
        margin-bottom: 60px;
    }

    @include media('md') {
        padding-right: 30px;
        margin-bottom: 120px;
    }
}

.showroom-title {
    @extend .font-headline-700;
    font-size: 28px;
    margin-bottom: 1em;
    text-wrap: balance;

    @include media('sm') {
        font-size: 30px;
    }

    @include media('md') {
        font-size: 40px;
    }
}

.showroom-address-block {
    display: flex;
    justify-content: space-between;
    text-wrap: balance;
    margin-bottom: 1em;
    margin-top: 1em;

    svg {
        display: none;

        @include media('md') {
            flex: 0 0 32px;
            display: block;
        }
    }
}

.showroom-address {
    font-size: 20px;
    color: var(--color-grey-4);
}

.showroom-contact__platforms {
    border-top: 1px solid var(--color-product-chrome-1);
    padding-top: 30px;
    margin-top: 30px;

    a {
        display: flex;
        align-items: center;
        color: var(--color-cyan-1);
        font-size: 16px;

        svg {
            width: 24px;
            color: var(--color-grey-2);
            margin-right: 16px;
        }

        + a {
            margin-top: 1em;
        }

        @include media('sm') {
            font-size: 18px;
        }
    }
}

.showroom-contact__details {
    margin-top: 40px;
    display: grid;
    grid-gap: calc(var(--gutter-width) / 2);
}

.showroom-contact__item {
    display: flex;
    flex-wrap: wrap;
    grid-gap: calc(var(--gutter-width) / 2);
}

.showroom-contact__item-key {
    @extend .font-headline-700;
    flex: 0 0 100%;

    @include media('sm') {
        flex: 0 0 150px;
    }
}

.showroom-hours {
    margin-top: 32px;
}

.showroom-hours__title {
    @extend .font-headline-700;
}

.showroom-social {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    margin: 21px 0 32px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background-color: var(--color-cyan-1);
        color: var(--color-white-1);
    }
    svg {
        height: 24px;
    }
}

.showroom-content__title {
    @extend .font-headline-700;
    font-size: 20px;
}

.showroom-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    grid-gap: 1em;

    a {
        width: 100%;
        text-align: center;

        @include media('sm') {
            width: unset;
            text-align: unset;
        }
    }
}

