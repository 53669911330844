
.accordion {

}

.accordion-item {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid var(--color-product-chrome-1);
    margin-bottom: var(--gutter-width);
}

.accordion-item .accordion-item__checkbox {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.accordion-item__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--gutter-width);
    cursor: pointer;
    transition: color 150ms ease-in;

    &:hover {
        color: var(--color-cyan-1);
    }
}

.accordion-item__label span {
    @extend .font-headline-700;
    font-size: 20px;
}

.accordion-item__label svg {
    width: 16px;
    color: var(--color-cyan-1);
    transition: transform 100ms ease-in;
}

.accordion-item__content-wrapper {
    max-height: 0;
}

.accordion-item__content {
    padding-bottom: var(--gutter-width);

    .content ul {
        margin-top: 0;
    }
}

.accordion-item__checkbox:checked {
    ~ .accordion-item__label svg {
        transform: rotate(180deg);
    }

    ~ .accordion-item__content-wrapper {
        max-height: 9999px;
    }
}
