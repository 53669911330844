
.block-addresses-default {
}

.block-addresses-default .block-title {
    @extend .font-headline-700;
    font-size: 26px;
    margin-bottom: var(--gutter-width);
}

.block-addresses-default .block-content {
    display: grid; 
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--gutter-width);

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    > div {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid var(--color-product-chrome-1);
        padding: var(--gutter-width);
    }

    .box-title {
        display: block;
        font-size: 18px;
        margin-bottom: .5em;
        @extend .font-text-700;
    }

    .box-actions {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
    }

    .action {
        @extend .font-text-700;
        text-decoration: underline;
        font-size: 14px;
        margin-top: 1em;
        color: var(--color-grey-2);

        span {
            color: inherit;
        }
    }
}
