
.feature-article-card {
    margin-top: 30px;

    @include media('sm') {
        margin-top: 44px;
    }

    @include media('md') {
        margin-top: 64px;
        display: grid;
        grid-template-columns: 40% auto;
    }

    @include media('lg') {
        grid-template-columns: 660px auto;
    }
}

.feature-article-card__image {
    height: 280px;
    background-size: cover;

    @include media('md') {
        height: auto;
    }
}

.feature-article-card__meta {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.feature-article-card__content {
    padding: var(--gutter-width);
    background-color: var(--color-white-3);

    @include media('sm') {
        padding: calc(var(--gutter-width) * 2);
    }
}

.feature-article-card__details {
    margin-top: 20px;

    a {
        margin-top: 30px;
    }

    @include media('sm') {
        margin-top: 30px;
    }

    @include media('md') {
        margin-top: 50px;
    }
}

.feature-article-card__title {
    @extend .font-headline-700;
    margin-top: 16px;
    font-size: 18px;

    @include media('sm') {
        font-size: 20px;
    }

    @include media('sm') {
        font-size: 22px;
    }

    @include media('md') {
        font-size: 30px;
    }
}

.feature-article-card__description {
    font-size: 16px;
    margin-top: 1em;

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        font-size: 20px;
    }
}
