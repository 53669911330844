.pill-group {
    display: flex;
    grid-gap: 8px;
}


.pill {
    @extend .font-text-700;
    --_color: var(--color, var(--color-grey-2));
    --_background: var(--background, var(--color-white-1));
    --_border: var(--border, var(--color-product-chrome-1));
    display: block;
    padding: 4px 10px;
    font-size: 14px;
    color: var(--_color);
    background: var(--_background);
    border: 1px solid var(--_border);
    // border-radius: 99px;
}

.pill-green {
    --color: var(--color-black-1);
    --background: var(--color-green-1);
    --border: var(--color-green-1);
    @extend .pill;
}

.pill-yellow {
    --color: var(--color-black-1);
    --background: var(--color-yellow-1);
    --border: var(--color-yellow-1);
    @extend .pill;
}

.pill-outline-cyan {
    --color: var(--color-brand-2);
    --background: transparent;
    --border: var(--color-brand-2);
    @extend .pill;
}

.pill-black {
    --color: var(--color-white-1);
    --background: var(--color-grey-1);
    --border: var(--color-grey-1);
    @extend .pill;
}

.pill-orange {
    --color: var(--color-white-1);
    --background: var(--color-orange-1);
    --border: var(--color-orange-1);
    @extend .pill;
}

.pill-blue {
    --color: var(--color-white-1);
    --background: var(--color-brand-1);
    --border: var(--color-brand-1);
    @extend .pill;
}

.pill-red {
    --color: var(--color-white-1);
    --background: var(--color-brand-3);
    --border: var(--color-brand-3);
    @extend .pill;
}

.pill-white-2 {
    --background: var(--color-white-2);
    --border: var(--color-white-2);
}
