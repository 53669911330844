
.account-menu {
    display: grid;
    grid-auto-flow: row;

    @include media('md') {
        // margin-top: 32px;
        max-width: 230px;
    }
}

.account-menu__link {
    @extend .font-headline-700;
    display: flex;
    grid-gap: 16px;
    padding: 10px;
    align-items: center;
    color: var(--color-grey-2);
    border-top: 1px solid var(--color-border-primary);
    border-bottom: 1px solid var(--color-border-primary);

    + .account-menu__link {
        border-top: unset;
    }

    svg {
        --stroke-color: var(--color-cyan-1);
        max-width: 32px;
        transform: translateY(1px);
        color: var(--color-cyan-1);
    }

    @include media('md') {
        border: unset;
    }
}

.account-menu__wishlist {
    svg {
        color: transparent;
    }
}

.account-menu__link:hover,
.account-menu__link--active {
    background-color: var(--color-white-2);
}

