
.count-indicator {
    --size: 24px;
    --background: var(--color-cyan-1);
    --text: var(--color-white-1);

    .count-indicator__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        background-color: var(--background);
        min-width: var(--size);
        min-height: var(--size);
    }

    .count-indicator__number {
        @extend .font-pt-700;
        font-size: calc(calc(var(--size) / 2) + 2);
        color: var(--text);
        padding: 0 .5em;
    }
}
