
.brand-hero {
    color: var(--color-grey-2);
    padding-bottom: 30px;

    @include media('sm') {
        padding-bottom: 48px;
    }

    @include media('md') {
        padding-bottom: 64px;
    }
}

.brand-hero__content {
    display: flex;
    justify-content: space-between;
    padding-right: var(--gutter-width);
}

.brand-hero__title {
    @extend .font-headline-700;
    font-size: 40px;

    @include media('md') {
        font-size: 48px;
    }
}

.brand-hero__logo {
    display: none;

    @include media('md') {
        display: block;
    }
}

.brand-hero__description {
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    margin-top: .5em;

    @include media('sm') {
        width: 80%;
    }

    @include media('md') {
        width: 60%;
        font-size: 20px;
    }

    @include media('lg') {
        width: 50%;
        margin-top: .25em;
    }
}
