.wishlist-quote-intro {
    margin-bottom: 40px;

}
.wishlist-quote-form {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: var(--gutter-width);
    margin-top: 32px;
    margin-bottom: 30px;

    @media (min-width: 1024px) {
        margin-bottom: 60px;
    }

    @media (min-width: 1600px) {
        margin-bottom: 90px;
    }

    .form {
        grid-column: 1 / span 12;

        @include media('md') {
            grid-column: 2 / span 8;
        }

        @include media('lg') {
            grid-column: 2 / span 7;
        }
    }

    .legend {
        border: none;
        padding-bottom: 0;
    }
}

.wishlist-quote-form__group {
    margin-bottom: 30px;
    padding: var(--gutter-width);
    border: 1px solid var(--color-product-chrome-1);

    .street .nested {
        margin-top: calc(var(--gutter-width) / 2);
    }
}
