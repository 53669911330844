
.brand-card {
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &:before {
        display: block;
        content: "";
        width: 100%;
        // padding-top: calc(calc(61 / 77) * 100%);
    }

    @include media('md') {
        &:hover {
            .brand-card__image {
                opacity: 0;
            }
            .brand-card__content {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    @media (hover: none) {
        .brand-card__image {
            opacity: 0;
        }

        .brand-card__content {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.brand-card__title {
    @extend .font-headline-700;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--color-white-1);
    text-shadow: 1px 1px 0 rgba(0,0,0,0.5);

    @include media('sm') {
        font-size: 24px;
    }

    @include media('md') {
        font-size: 32px;
    }

    @include media('lg') {
        font-size: 40px;
    }
}

.brand-card__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-size: cover;
    background-position: center;
    transition: opacity 100ms ease-in;
}

.brand-card__content {
    // position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: var(--gutter-width);
    padding: var(--gutter-width);
    background-color: var(--color-grey-blue-1);
    transform: scale(1.05);
    opacity: 0;
    transition: opacity 150ms ease-in, transform 150ms ease-in;
    min-height: 100%;

    @include media('lg') {
        padding: 50px;
    }

    .brand-card__logo {
        img {
            max-width: 126px;
        }
    }
}

.brand-card__description {
    margin-top: 16px;
    font-size: 14px;
    color: var(--color-text-primary);

    @include media('xl') {
        margin-top: 24px;
        font-size: 16px;
    }
}

.brand-card__actions a {
    + a {
        margin-left: 1em;
    }
}
