
.product-page__products-slider {

    .products-slider__content {
        position: relative;
    }

    .products-slider__title {
        font-size: 40px;
    }

    .products-slider__description-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .products-slider-controls {
            position: static;
            display: flex;
            align-items: center;
            grid-gap: 10px;
        }
    }

    .products-slider__description {
        display: none;
    }
}
