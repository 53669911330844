.feature-link {
    display: flex;
    min-height: 380px;
    margin-top: 60px;
    margin-bottom: 60px;

    @include media('sm') {
        margin-top: 90px;
        margin-bottom: 90px;
    }
}

.feature-link__content-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    background-color: rgba(53, 51, 43, .8);
}

.feature-link__content {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    a {
        margin-top: 2em;
    }
}

.feature-link__title {
    @extend .font-headline-700;
    font-size: 24px;
    color: var(--color-white-1);
    margin-top: .5em;

    @include media('xs') {
        font-size: 26px;
    }

    @include media('sm') {
        font-size: 28px;
    }

    @include media('md') {
        font-size: 30px;
    }
}

.feature-link__description {
    font-size: 18px;
    color: var(--color-white-1);
    margin-top: .25em;

    @include media('sm') {
        font-size: 20px;
    }
}

.feature-link__icon {
    width: 43px;
    color: var(--color-white-1);
    margin: 0 auto;
}
