
.page-length {

    a, span {
        font-size: 14px;
        color: var(--color-black-1);
    }
    
    a{
        margin-left: .5em;
    }

    .current {
        font-weight: 600;
    }
}
