// YAY! no more JS for this
body:has(#mobile-menu:checked) {
    overflow: hidden;

    .mobile-menu {
        display: block;
        pointer-events: all;
    }
}

#mobile-menu:checked ~ .main .mobile-menu {
    display: block;
    pointer-events: all;
}

.mobile-menu {
    display: none;
    pointer-events: none;
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 999;
    background-color: var(--color-white-1);
    width: 100%;
    height: 100%;
    padding: var(--gutter-width);
}

.mobile-menu__header {
    position: sticky;
    z-index: 100;
    top: 0;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    min-height: 50px;
    background-color: var(--color-white-1);
}

.mobile-menu__navigation-container {
    pointer-events: none;
}

.mobile-menu__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
}

.mobile-menu__back {
    display: none;
    pointer-events: none;
    font-family: var(--font-headline-700);
    cursor: pointer;

    svg {
        color: var(--color-cyan-1);
        width: 1.5em;
        margin-right: .5em;
    }
}

.mobile-menu .main-logo {
    display: block;
    width: calc(var(--header-height) - var(--gutter-width) * 2);

    svg {
        width: 100%;
    }
}

.mobile-menu--active {
    .main-logo {
        display: none;
    }
    .mobile-menu__back {
        display: flex;
        pointer-events: all;
    }
}

.mobile-menu__primary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-menu__navigation {
    display: grid;
}

.mobile-menu__template {
    display: none;
}

.mobile-menu__title {
    position: sticky;
    top: 0;
    font-size: 30px;
    font-family: var(--font-headline-700);
    padding-bottom: 20px;
    color: var(--color-grey-1);
    background-color: var(--color-white-1);
}

.mobile-menu__link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-text-500);
    padding: .75em 0;
    cursor: pointer;
    color: var(--color-grey-1);

    svg {
        width: 1em;
        color: var(--color-cyan-1);
    }
}

.mobile-menu__list {
    column-count: 2;
}

.mobile-menu__block {
    margin-bottom: 20px;
}

.mobile-menu__block-title {
    display: block;
    font-size: 20px;
    font-family: var(--font-headline-700);
    color: inherit;
    border-top: 1px solid var(--color-product-chrome-1);
    padding-top: 30px;
    margin-bottom: .5em;
}

.mobile-menu__content-wrapper {
    display: flex;
    flex-grow: 1;
    width: calc(100vw - var(--gutter-width) * 2);
    margin-top: 30px;
    overflow: hidden;
}

.mobile-menu__content {
    flex: 0 0 200%;
    width: 200%;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.mobile-menu--active .mobile-menu__content {
    translate: -50% 0;
}

.mobile-menu__actions-wrapper {
    display: grid;
    grid-gap: 20px;
}

.mobile-menu__actions {
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: column;
}
