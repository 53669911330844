#product-mobile-filter {
    position: absolute;
    opacity: 0;
    top: -999px;
    left: -999px;
}

.product-mobile-filter {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--color-white-1);
    overflow: scroll;
    display: none;

    &:has(#product-mobile-filter:checked) {
        display: block;
    }
}

.product-mobile-filter__container {
    padding: var(--gutter-width);
}

.product-mobile-filter__titlebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--gutter-width);

    [for="product-mobile-filter"]{
        width: auto;
    }
}

.product-mobile-filter__title {
    font-size: 20px;
    font-family: var(--font-headline-700);

    @include media('sm') {
        font-size: 28px;
    }
}

.product-mobile-filter__close {
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    svg {
        width: 24px;
    }
}

.product-mobile-filter__content {
    padding-bottom: calc(82px + var(--gutter-width)); // Make space for footer
}

.product-mobile-filter__content .sidebar-block {
    display: block;
}

.product-mobile-filter__content .sidebar-filters {
    display: block;
}

.product-mobile-filter__footer {
    position: fixed;
    bottom: 0;
    width: calc(100%);
    padding: var(--gutter-width);
    background-color: var(--color-white-1);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.product-mobile-filter__actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter-width);
}
