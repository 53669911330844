
.search-form {
    // max-height: 48px;

    form {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        margin: 0;
    }
    
    input {
        flex-grow: 1;
        height: inherit;
        padding: 1em 1.5em;
        border: 1px solid var(--color-white-2);
        border-radius: 9999px;
        background-color: var(--color-white-2);
    }

    button {
        position: absolute;
        right: 1.5em;
        padding: 0;
        width: 24px;
        height: 24px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
}
