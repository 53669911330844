

.shipping-address-items {
    display: grid;
    grid-gap: var(--gutter-width);
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.shipping-address-items 
.shipping-address-item {
    --_text-color: var(--text-color, var(--color-grey-2));
    --_background-color: var(--background-color, transparent);
    --_border-color: var(--border-color, var(--color-product-chrome-1));
    --_link-color: var(--link-color, var(--color-brand-2));
    padding: var(--gutter-width);
    border: 1px solid var(--_border-color);
    background-color: var(--_background-color);
    border-radius: 4px;
    color: var(--_text-color);
    cursor: pointer;
    position: relative;

    &:not(.selected-item):hover {
        border-color: var(--color-brand-2);
    }

    /*.edit-address-link {
        position: relative;
        z-index: 100;
        display: block !important; // @TODO this is overwriting a display none we should probably fix the knockout that does this
    }*/

    .action-select-shipping-item {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
    }

    a {
        color: var(--_link-color);
    }

    &.selected-item {
        --text-color: var(--color-white-1);
        --background-color: var(--color-brand-2);
        --link-color: var(--color-white-1);
        --border-color: var(--color-brand-2);
    }
}

.shipping-address-items 
.action-select-shipping-item,
.shipping-address-items 
.edit-address-link {
    color: inherit;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    border: none;
    margin-top: var(--gutter-width);
    margin-right: var(--gutter-width)
}
