@use 'sass:color';

:root {
    --color-black-1: #000000;
    --color-white-1: #FFFFFF;
    --color-white-2: #F5F5F5;
    --color-white-3: #F3F3F3;
    --color-grey-1: #3A3A3A;
    --color-grey-2: #35332B;
    --color-grey-3: #C8C8C8;
    --color-grey-4: #9C9C9C;
    --color-grey-blue-1: #CCDFE1;
    --color-cyan-1: #00AABB;
    --color-product-black-1: #494949;
    --color-product-gold-1: #D1B567;
    --color-product-gold-2: #B19B65;
    --color-product-chrome-1: #D9D9D9;

    --color-linkedin-1: #0077B5;
    --color-twitter-1: #1Da1F2;
    --color-facebook-1: #4267B2;

    --color-brand-1: #183C8E;
    --color-brand-2: #00AABB;
    --color-brand-2--disabled: #9bc4c7;
    --color-brand-3: #F0492F;

    --color-orange-1: #E3731B;
    --color-yellow-1: #F4E01C;
    --color-green-1: #00e83a;

    --color-scheme-1: #143663;

    --color-brand-2-dark: #{darken(#00AABB, 10%)};

    --color-text-primary: var(--color-grey-2);

    --color-border-primary: #D9D9D9;

    --color-info-background: #BDD1FF;
    --color-warning-background: #FFEEBD;
    --color-error-background: #FFADA0;

}
