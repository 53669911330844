

.planner-guide .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.planner-guide__instructions,
.planner-guide__showcase {
    grid-column: span 12;

    @include media('lg') {
        grid-column: span 6;
    }
}

.planner-guide__instructions {
    margin-bottom: 30px;

    h2 {
        text-align: center;
    }

    @include media('sm') {
        background-color: var(--color-white-2);
        padding: 30px;
        margin-bottom: unset;
    }

    @include media('md') {
        padding: 60px;

        h2 {
            text-align: left;
        }
    }

    @include media('lg') {
        padding: 80px 70px;
    }

}

.planner-guide__showcase {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-scheme-1);
}

.planner-guide__slider-wrapper {
    width: 590px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 30px;

    @include media('lg') {
        padding: 0;
    }
}

.planner-guide__slider-controls {
    position: absolute;
    bottom: 30px;
    right: 30px;

    @include media('lg') {
        bottom: 0;
        right: 0;
    }

    button {
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    svg {
        color: var(--color-white-1);
        width: 24px;
    }
}

.planner-guide__slider-item__description {
    color: var(--color-white-1);
    margin-top: 24px;
}

