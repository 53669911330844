
.showroom-link {
    @extend .font-headline-700;
    display: flex;
    font-size: 14px;
    align-items: center;
    white-space: nowrap;
    color: var(--color-grey-1);
    transition: color 100ms ease-in;

    @include media('md') {
        font-size: 16px;
    }

    &:hover {
        color: var(--color-brand-2);

        svg {
            animation: bounce 1s infinite;
        }
    }

    svg {
        width: 26px;
        color: var(--color-cyan-1);
        margin-right: .5em;
    }
}
