.product-colorpicker {
    position: relative;
    display: block;

    .swatch-attribute:not(:first-child) {
        margin-top: var(--gutter-width);
    }

    //@for $i from 1 through 20 {
    //    input:nth-child(#{$i}):checked {
    //        ~ .product-colorpicker__options {
    //            label:nth-child(#{$i}) {
    //                border: 2px solid var(--color-cyan-1);
    //                box-shadow: inset 0px 0px 0px 2px var(--color-white-1);
    //            }
    //        }
    //        ~ .product-colorpicker__label .product-colorpicker__values {
    //            span:nth-child(#{$i}) {
    //                display: block;
    //            }
    //        }
    //    }
    //}
}

.product-colorpicker input {
    opacity: 0;
    position: absolute;
}

.product-colorpicker__key {
    @extend .font-headline-700;
}

.product-colorpicker__label {
    display: flex;
    justify-content: space-between;

    @include media('sm') {
        justify-content: unset;
    }
}

.product-colorpicker__values {
    margin-left: .5em;
}

.swatch-attribute.swatch-layered.color {
    .swatch-attribute-options {
    }
}

/* IMPORTANT .swatch-attribute.[CLASS_NAME] matches the name of the field in magento */
.product-colorpicker .swatch-attribute.color .swatch-attribute-options,
.product-colorpicker .swatch-attribute.colour_name .swatch-attribute-options:not(:has(select)) {
    display: grid;
    grid-auto-flow: column dense;
    grid-auto-columns: 40px;
    grid-gap: 8px;
    margin-top: 16px;
    width: 100%;
}

.swatch-attribute.color .swatch-attribute-options {
    margin-left: 3px; // This is to prevent the outliner from clipping
    margin-top: 3px;
}

/*.product-colorpicker__value {
    display: none;
}*/

.swatch-option-tooltip {
    background-color: var(--color-grey-1);
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: -4px;
}

.swatch-option-tooltip .corner {
    left: 50% !important;
    translate: -50% 0 !important;
}

.swatch-option-tooltip .corner:after {
    border-color: var(--color-grey-1) transparent transparent transparent;
    top: -3px;
    left: 0;
}

.swatch-option-tooltip .corner:before {
    display: none;
}

.swatch-option-tooltip .title {
    color: var(--color-white-1);
}

.swatch-attribute.color .swatch-attribute-options a div,
.product-colorpicker__color {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: currentColor;
    border: 2px solid var(--color-grey-3);
    transition: scale 150ms ease-in,
        border-color 150ms ease-in,
        box-shadow 150ms ease-in;

    &:hover {
        scale: 1.05;
    }

    &.selected {
        border: 2px solid var(--color-cyan-1);
        box-shadow: inset 0px 0px 0px 2px var(--color-white-1);
    }

    &.disabled {
        //opacity: 0.3;
        background-color: color-mix(in srgb, currentColor 30%, transparent);

        &:before {
            display: block;
        }
        &:hover {
            scale: unset;
        }
    }

    &:before {
        display: none;
        z-index: 15;
        content: '\D7';
        position: absolute;
        top: 40%;
        left: 50%;
        translate: -50% -50%;
        text-align: center;
        color: var(--color-brand-3);
        font-size: 26px;
        text-transform: uppercase;
        //background-color: var(--color-white-1);
        //border: 1px solid var(--color-product-chrome-1);
    }

    &.image {
        background-size: cover;
        background-position: center;
        background-color: transparent;
    }
}

.swatch-attribute.color .swatch-attribute-options a div {
    border: none;
}

.product-colorpicker__color,
.swatch-option.color,
.swatch-option.image {
    &:not(.disabled):hover {
        outline: 2px solid var(--color-cyan-1);
    }
}
