
.subscribe-dialog {
    position: fixed;
    right: 24px;
    z-index: 200;
    padding: 0;
    border: 0;
    bottom: 24px;
    margin-right: 0;
    border-radius: 4px;
    padding: 24px;
    background-color: var(--color-cyan-1);
    transform: translateX(100%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    &[open] {
        animation: 500ms fly-in-right normal forwards;
    }

    &.hide {
        animation: 500ms fly-out-right normal forwards;
    }
}

.subscribe-dialog-content {
    position: relative;
    width: 284px;
}

.subscribe-dialog__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background: transparent;
    width: 24px;
    height: 24px;
    transform: translate(50%, -50%);
    cursor: pointer;
    outline: none;

    svg {
        width: 15px;
        color: var(--color-white-1);
    }
}

.subscribe-dialog__title,
.subscribe-dialog__description {
    color: var(--color-white-1);
}

.subscribe-dialog__title {
    @extend .font-text-500;
    text-transform: uppercase;
}

.subscribe-dialog__description {
    @extend .font-text-700;
    margin-top: 1em;
    margin-bottom: 1em;
}

.subscribe-dialog .form {
    margin-bottom: 0;
}
