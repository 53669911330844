
.product-form__totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: 2em;

    &.product-form__totals-sublist {
        margin-top: 12px;
        font-size: 16px;
        @extend .font-headline-400;
    }
}

.product-form__totals-key {
    @extend .font-headline-700;
}

.product-form__totals-before {
    @extend .font-headline-400;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background: var(--color-grey-2);
        transform: translateY(200%);
    }
}

.product-form__totals {
    @extend .font-headline-700;
    margin-top: 8px;
    font-size: 15px;
    color: var(--color-text-primary);

    @include media('lg') {
        font-size: 18px;
    }
}

.product-form__totals-after {
    @extend .font-headline-700;
    color: var(--color-text-primary);
    margin-left: 10px;

    @include media('lg') {
        font-size: 18px;
    }
}

.price-container .price {
    font-size: inherit;
}
