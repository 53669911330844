
.article-content-section {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media('xs') {
        margin-bottom: 60px;
    }

    @include media('sm') {
        margin-top: 60px;
        margin-bottom: 90px;
    }

    @include media('md') {
        margin-bottom: 120px;
    }

    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

