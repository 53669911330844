
.item-list-section {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media('sm') {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.item-list-section .pagination {
    justify-content: center;
    margin-top: 30px;

    @include media('xs') {
        margin-top: 60px;
    }

    @include media('sm') {
        margin-top: 90px;
    }
}
