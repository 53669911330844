
.favourite {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
        position: absolute;
        opacity: 0;
    }

    svg {
        width: 20px;
        margin-right: .5em;
        transition: transform 150ms ease-in;
        stroke: var(--color-cyan-1);
        color: transparent;
    }
}

.favourite:hover {
    svg {
        transform: scale(1.05);
    }
}

.favourite.force-checked {
    svg {
        color: var(--color-cyan-1);
    }

    .favourite-text {
        color: var(--color-cyan-1);
    }
}

.favourite input:checked {
    ~ svg {
        color: var(--color-cyan-1);
    }

    ~ .favourite-text {
        color: var(--color-cyan-1);
    }

    ~ .favourite-text span {
        display: inline;
    }
}

.favourite-text {
    font-size: 14px;
    color: var(--color-grey-2);

    span {
        display: none;
    }
}
