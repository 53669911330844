.product-form {
    margin-top: var(--gutter-width);
}

.product-form__section {
    border-top: 1px solid var(--color-product-chrome-1);
    padding: var(--gutter-width) 0;
}

.product-form__accordions {
    margin-top: 40px;
}

.product-form__social {
    padding-top: 0;
    border-top: 0;
}

.product-form__instore {
    margin-top: 20px;

    a.btn-brand-2 {
        width: 100%;
        margin-top: 20px;
    }
}
