.compare-main-wrapper {
    position: relative;
}

.compare-controls {
    position: fixed;
    z-index: 100;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 90%;
    pointer-events: none;

    @media (min-width: 768px) {
        display: none;
    }

    button {
        padding: 0;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        border: none;
        pointer-events: all;
        box-shadow: 0px 2px 8px 0px #00000040;
        background-color: var(--color-white-1);

        svg {
            width: 40%;
        }

        &:focus {
            box-shadow: 0px 2px 8px 0px #00000040 !important;
        }
    }
}

.compare-main {
    display: grid;
    margin-bottom: 30px;
    // border-top: 1px solid var(--color-product-chrome-1);

    @include media('xs') {
        margin-bottom: 60px;
    }

    @include media('sm') {
        padding-top: 24px;
        margin-bottom: 90px;
    }

    @include media('md') {
        margin-bottom: 120px;
    }

    @include media('lg') {
        grid-template-columns: 230px auto;
    }
}

.compare-list {
    overflow: hidden;
    // grid-template-columns: repeat(4, 1fr);
}

.compare-list__product-line {
    // This is handled by the slider
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
}
