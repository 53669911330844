.brand-content {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media('sm') {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @include media('md') {
        margin-top: 90px;
        margin-bottom: 90px;
    }
}
