
.basic-cta {
    margin: 60px 0;

    @include media('sm') {
        margin: 90px 0;
    }

    @include media('md') {
        margin: 120px 0;
    }

    @include media('lg') {
        margin: 140px 0;
    }

    a {
        margin-top: 1em;
    }
}
