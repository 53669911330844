
.block-addresses-list {
    margin-bottom: 30px;
}

.block-addresses-list + .actions-toolbar {
    margin-bottom: 30px;

    @media (min-width: 1024px) {
        margin-bottom: 60px;
    }

    .action.primary {
        @extend %btn;
        @extend .btn-thin;
        @extend .btn-outline-brand-2;
    }
}
