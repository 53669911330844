.header .minicart-wrapper {
    margin-left: 22px;
}

// overwrite
.minicart-wrapper .block-minicart {
    padding: 0;
}

// overwrite
.minicart-wrapper .action.close {
    position: unset;
    height: unset;
    width: unset;
    top: unset;
    right: unset;
    display: flex;
    align-items: center;
}

// overwrite
.minicart-items-wrapper {
    padding: 0;
    margin: 0;
    border: none;
}

// overwrite
.minicart-items .product-item-details {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    padding-left: 0;
    flex-grow: 1;
}

// overwrite
.minicart-items .product-item-pricing {
    display: flex;
    align-items: center;
}

.minicart-items .product-item-name {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.minicart-items .product-item-details .price {
    @extend .font-headline-400;
    font-weight: 400;
    font-size: 16px;
}

.minicart-items .product-item-name a {
    color: inherit;
    font-size: 14px;
    @extend .font-headline-700;
}

// overwrite
.minicart-items .product > .product-item-photo,
.minicart-items .product > .product-image-container {
    float: unset;
}

// overwrite
.minicart-items .product-image-container {
    width: 50px !important;

    img {
        scale: 1;
    }
}

// overwrite
.minicart-items .product-item {
    padding: 16px 0;
}

.minicart-items .product-item:first-child {
    padding-top: 0;
}

.minicart-items .product-item:last-child {
    padding-bottom: 0;
}

// overwrite
.minicart-items .product-item:not(:first-child) {
    border-top: 1px solid var(--color-product-chrome-1);
}

.minicart-items .product-item .product {
    &:before,
    &:after {
        display: none;
    }
}

// overwrite
.minicart-items .product > .product-item-photo,
.minicart-items .product-item .product {
    display: flex;
}

// overwrite
.minicart-items .product {
    grid-gap: 16px;
}

//overwrite
.minicart-items .product-image-wrapper .product-image-photo {
    position: absolute;
}

//overwrite
.minicart-items .update-cart-item {
    border: none;
    padding: 0px;
    margin: 0;

    svg {
        width: 28px;
    }
}

//overwrite
.minicart-items .details-qty {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;

    input:focus {
        box-shadow: none;
    }
}

//overwrite
.product-item__delete {
    color: var(--color-brand-3) !important;
    padding: .5em;

    &:before {
        display: none !important;
    }

    svg {
        width: 24px;
    }
}

.minicart-notice {
    color: var(--color-grey-4);
    margin-bottom: 1em;
    font-size: 14px;
}

.minicart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white-2);
    padding: 16px;
}

.minicart-titlebar {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.minicart-titlebar .indicator {
    position: unset;
    top: unset;
    right: unset;
    translate: unset;
    font-size: 14px;
    @extend .font-headline-700;
}

.minicart-header__title {
    @extend .font-headline-700;
}

.minicart-container {
    padding: 16px;
}

.minicart-footer {
    border-top: 1px solid var(--color-product-chrome-1);
    padding: 16px;
}

.minicart-footer a {
    width: 100%;
    text-align: center;
}

.minicart-subtotal {
    display: flex;
    justify-content: space-between;
}

.minicart-subtotal .subtotal .label {
    display: none;
}

.block-minicart .subtotal {
    margin: 0;
    text-align: left;
}

.minicart-subtotal__title {
    @extend .font-headline-700;
}

.minicart-wrapper .loader-outer {
    top: 8px;
    left: 8px;

    .loader-icon {
        width: 30px;
        height: 30px;

        div {
            width: 22px;
            height: 22px;
            border-width: 2px;
        }
    }
}
