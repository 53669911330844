
.shipping-details {
    display: grid;
    grid-gap: 30px;
    margin-top: 30px;

    > *  {
        min-width: 0px;
    }

    @media (min-width: 768px) {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 1024px) {
        max-width: 100%;
        grid-template-columns: 60% calc(40% - 30px);
        margin-left: unset;
        margin-right: unset;
    }

    @media (min-width: 1200px) {
    }

    @media (min-width: 1480px) {
        grid-template-columns: 698px 360px;
        grid-gap: 58px;
    }

    @media (min-width: 1600px) {
        grid-template-columns: 798px 360px;
    }
}


.shipping-sidebar {

    .notice {
        margin-top: 30px;
    }
}
