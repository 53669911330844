
.menu-links {
    display: flex;

    a {
        transition: color 100ms ease-in;
    }

    a:hover {
        color: var(--color-brand-2);
    }

    a + a {
        margin-left: 24px;

        @include media('md') {
            margin-left: 50px;
        }
    }
}
