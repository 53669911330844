
.listing-footer {
    margin-top: 30px;

    @include media('sm') {
        margin-top: 60px;
    }
}

.listing-footer .pagination {
    justify-content: center;
}
