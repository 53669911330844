
.brand-showcase {
    margin-top: 60px;

    @include media('sm') {
        margin-top: 60px;
    }

    @include media('md') {
        margin-top: 100px;
    }

    @include media('lg') {
        margin-top: 120px;
    }
}

.brand-showcase__title {
    @extend .font-headline-700;
    font-size: 32px;
    margin: 0;
    text-align: center;
    margin-bottom: 30px;
    text-wrap: balance;


    @include media('sm') {
        font-size: 32px;
    }

    @include media('md') {
        font-size: 40px;
        margin-bottom: 50px;
    }
}

