
.primary-navigation {
    display: flex;
}

.primary-navigation .main-logo {
    width: 54px;

    svg {
        max-width: 100%;
    }
}

.primary-navigation .menu-links {
    margin-left: 30px;

    @include media('md') {
        margin-left: 70px;
    }
}
