
.product-counter {
    flex-grow: 0;
    border: 1px solid var(--color-grey-3);
    border-radius: 999px;
    overflow: hidden;
    display: inline-flex;
    justify-content: space-between;
    // max-width: 150px;
    height: 50px;
}

.product-form__section .product-counter__value {
    @extend .font-headline-700;
    display: inline-flex;
    flex-grow: 1;
    border: none;
    text-align: center;
    appearance: textfield;
    width: 50px;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 2px;
    font-size: 1em;
}

.cart-item .product-counter input {
    @extend .font-headline-700;
    height: unset;
    text-align: center;
    font-size: 16px;
    border: none;
    min-width: 2em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.cart-item .product-counter {
    max-width: 150px;
}

.product-counter__decrement,
.product-counter__increment {
    border: none;
    padding: 0 18px;
    background-color: transparent;
    cursor: pointer;
    color: var(--color-grey-3);
    transition: background 150ms ease-in;

    &:hover, &:active, &:focus {
        background-color: var(--color-white-2);
        border: none;
        color: var(--color-grey-2);
        box-shadow: none !important;
    }

    svg {
        width: 1em;
    }
}
