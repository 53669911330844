
.simple-back {
    display: flex;
    align-items: center;
    color: var(--color-grey-1);

    svg {
        width: 16px;
        transform: translateY(2px);
        margin-right: .5em;
        color: var(--color-cyan-1);
    }
} 
