
.tabbed-accordion {
    padding-top: 30px;
    margin-bottom: 30px;

    input {
        position: absolute;
        opacity: 0;
    }

    @include media('xs') {
        padding-top: 60px;
        margin-bottom: 60px;
    }

    @include media('sm') {
        padding-top: 75px;
        margin-bottom: 90px;
    }
}

.tabbed-accordion__title {
    @extend .font-headline-700;
    color: var(--color-grey-2);
    text-align: center;
    font-size: 38px;

    @include media('sm') {
        font-size: 40px;
    }

    @include media('md') {
        font-size: 48px;
    }

    @include media('lg') {
        font-size: 56px;
    }
}

.tabbed-accordion__tab-input {

    @for $i from 1 through 5 {
        &:nth-child(#{$i}):checked {

            ~ .tabbed-accordion__labels
            .tabbed-accordion__label:nth-child(#{$i}) {
                border-bottom: 3px solid var(--color-cyan-1);
                color: var(--color-black-1);
                opacity: 1;

                @include media('sm') {
                    border-bottom: 5px solid var(--color-cyan-1);
                }
            }

            ~ .tabbed-accordion__tabs
            .tabbed-accordion__content:nth-child(#{$i}) {
                max-height: 99999px;
            }
        }
    }
}

.tabbed-accordion__labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include media('sm') {
        justify-content: center;
    }
}

.tabbed-accordion__label {
    @extend .font-headline-700;
    margin: 20px;
    padding-bottom: .75em;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.5;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 100%;

    @media (min-width: 375px) {
        text-align: center;
        flex: 0 0 50%;
    }

    @include media('sm') {
        flex: unset;
        text-align: left;
        margin: 30px;
        font-size: 16px;
        border-bottom: 5px solid transparent;
        padding-left: unset;
        padding-right: unset;
        margin-left: 30px;
        margin-right: 30px;
    }

    @include media('md') {
        margin: 40px;
    }
}

.tabbed-accordion__content {
    max-height: 0;
    overflow: hidden;
}

.tabbed-accordion__accordion-input {
    &:checked {
        ~ .tabbed-accordion__accordion-label {
            .tabbed-accordion__indicator:before {
                display: none;
            }
        }

        ~ .tabbed-accordion__accordion-content {
            max-height: 9999px;
            opacity: 1;
        }
    }

    //@for $i from 1 through 20 {
    //
    //    &:nth-child(#{$i}):checked {
    //        ~ .tabbed-accordion__accordions
    //        .tabbed-accordion__accordion:nth-child(#{$i})
    //        .tabbed-accordion__accordion-label {
    //            .tabbed-accordion__indicator:before {
    //                display: none;
    //            }
    //        }
    //
    //        ~ .tabbed-accordion__accordions
    //        .tabbed-accordion__accordion:nth-child(#{$i})
    //        .tabbed-accordion__accordion-content {
    //            max-height: 9999px;
    //            opacity: 1;
    //        }
    //    }
    //}
}

//.accordion-item__checkbox:checked {
//    ~ .accordion-item__label svg {
//        transform: rotate(180deg);
//    }
//
//    ~ .accordion-item__content-wrapper {
//        max-height: 9999px;
//    }
//}

.tabbed-accordion__accordion +
.tabbed-accordion__accordion {
    margin-top: 16px;
}

.tabbed-accordion__accordion-label {
    @extend .font-headline-700;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: var(--color-white-1);
    cursor: pointer;

    .tabbed-accordion__indicator {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            background-color: var(--color-cyan-1);
        }

        &:before {
            left: calc(50% - 1.5px);
            width: 3px;
            height: 20px;
        }

        &:after {
            position: absolute;
            top: calc(50% - 1.5px);
            width: 20px;
            height: 3px;
        }
    }
}

.tabbed-accordion__accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    background-color: var(--color-white-1);
}

.tabbed-accordion__accordion-content-inner {
    padding: 0 24px 24px;
}
