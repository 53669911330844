
.subscribe {
    padding: 30px 0;
    background-color: var(--color-grey-blue-1);
    text-align: center;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @include media('sm') {
        text-align: left;
    }

    @include media('md') {
        padding: 60px 0;
    }
}

.subscribe-content {
    width: 100%;
    margin: 0 auto;

    @include media('sm') {
        width: unset;
        margin: unset;
        padding-right: var(--gutter-width);
        margin-bottom: var(--gutter-width);
    }
}

.subscribe__title {
    @extend .font-headline-700;
    max-width: 80%;
    margin: 0 auto;
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 10px;

    @include media('sm') {
        text-align: left;
        font-size: 30px;
        max-width: unset;
        margin: unset;
    }
}

.subscribe__description {
    @extend .font-pt-400;
    margin-top: .25em;
    font-size: 18px;

    width: 100%;
    margin: 0 auto;
    margin-bottom: var(--gutter-width);
    max-width: 300px;

    @include media('sm') {
        width: unset;
        margin: usnet;
        max-width: unset; 
    }
}

.subscribe-form__fields {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: var(--gutter-width);
    grid-auto-flow: column;

    .field {
        height: 48px;
        margin: 0 auto;
        margin-top: 0;
        width: 100%;
        max-width: 300px;

        @include media('sm') {
            max-width: unset;
            width: unset;
            margin: unset;
        }
    }

    input[type="submit"] {
        padding: 14px 32px;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;

        @include media('sm') {
            max-width: unset;
            width: unset;
            margin: unset;
        }
    }

    input[type="text"],
    input[type="email"] {
        min-width: 280px;
        height: 100%;
        padding-left: 1em;
        border: none;
        border-radius: 4px;

        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.subscribe-form__disclaimer {
    color: var(--color-grey-1);
    font-size: 12px;
    margin-top: 1em;

    span {
        opacity: .4;
    }

    a {
        opacity: .5;
        text-decoration: underline;
        color: inherit;
        margin-left: .7em;
    }
}
