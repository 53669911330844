
@keyframes bounce {
    from, to {
        transform: scale(1, 1) translateY(0);
    }
    25% {
        transform: scale(.9, 1.1) translateY(-20%);
    }
    50% {
        transform: scale(1.1, .9) translateY(1%);
    }
    75% {
        transform: scale(.95, 1.05) translateY(0%);
    }
}

@keyframes fly-in-right {
    0% {
        transform: translateX(200%);
    }
    33% {
        transform: translateX(-5%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes fly-out-right {
    0% {
        transform: translateX(0%);
    }
    33% {
        transform: translateX(-10%);
    }
    100% {
        transform: translateX(200%);
    }
}

@keyframes fly-in-up {
    0% {
        transform: translateY(200%);
    }
    33% {
        transform: translateY(1%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes fly-out-down {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(200%);
    }
}

@keyframes fly-in-down {
    0% {
        transform: translateY(-200%);
    }
    33% {
        transform: translateY(1%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes fly-out-up {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-200%);
    }
}
