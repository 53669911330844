
.basic-split {
    padding-bottom: 30px;

    @include media('sm') {
        padding-bottom: 60px;
    }

    @include media('md') {
        padding-bottom: 90px;
    }

    @include media('lg') {
        padding-bottom: 140px;
        padding-left: 140px;
        padding-right: 140px;
    }
}

.basic-split-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.basic-split__actions {
    margin-top: 24px;
    text-align: center;

    @include media('md') {
        text-align: left;
    }
}

.basic-split__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: span 12;
    padding-right: 1em;
    text-align: center;

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        text-align: left;
        font-size: 20px;
        grid-column: 1 / span 6;
    }

    @include media('lg') {
        grid-column: 2 / span 5;
    }
}

.basic-split__image {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    img {
        display: block;
        margin: 0 auto;
    }

    @include media('md') {
        margin-top: 0;
        grid-column: 7 / span 6;
    }
}
