footer {
    border-top: 1px solid var(--color-product-chrome-1);
}

.footer-main {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;

    @include media('md') {
        padding: 64px 0;
    }
}

.footer-main__primary {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;

    @include media('md') {
        width: 200px;
        margin-right: 40px;
        margin-bottom: 0px;
    }

    @include media('xl') {
        width: 264px;
    }
}

.footer-main__primary .main-logo {
    display: block;

    @include media('md') {
        text-align: left;
    }

    svg {
        width: 106px;
    }
}

.footer-main__primary .showroom-link {
    margin-top: 32px;
    justify-content: center;

    @include media('md') {
        justify-content: flex-start;
    }
}

.footer-main__secondary {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin: 0 auto;
    grid-gap: 40px;

    a {
        color: var(--color-grey-1);
        transition: color 100ms ease-in;

        &:hover {
            color: var(--color-brand-2);
        }
    }

    @include media('sm') {
        flex-grow: 1;
        margin: unset;
        grid-auto-flow: column;
    }

}

.footer-main__menu-list {
    display: grid;
    grid-auto-flow: row;
    margin-top: 1em;
}

.footer-main__menu-title {
    @extend .font-headline-700;
}

.footer-main__menu-item {
    font-size: 14px;
    padding: .5em 0;
}

.footer-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-product-chrome-1);
    padding-top: 32px;
    padding-bottom: 32px;

    a {
        color: var(--color-grey-1);
    }

    @include media('sm') {
        flex-direction: unset;
        padding-bottom: 16px;
    }
}

.footer-summary__notice {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1em;
    order: 1;
    margin-top: 40px;

    div {
        display: flex;
        grid-gap: 1em;
    }

    @include media('sm') {
        display: unset;
        flex-wrap: unset;
        align-items: unset;
        justify-content: unset;
        grid-gap: unset;
        margin-top: unset;
        order: unset;

        div {
            display: unset;
            grid-gap: unset;
        }

        span,
        a {
            margin-right: 1em;
        }
    }
}

.footer-summary__social {
    order: 0;
    @include media('sm') {
        order: unset;
    }
    svg {
        color: inherit;
        height: 24px;
    }
}
