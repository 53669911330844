
.brand-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;

    @include media('xs') {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media('sm') {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--gutter-width); 
    }

    @include media('lg') {
        grid-gap: 40px; 
    }
}
