
.modal-popup {
    left: 0 !important;

    .modal-header {
        position: relative;
        padding: 0;

        .action-close {
            translate: -50% 50%;
            top: 0; right: 0;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            padding: 0;
            margin: 0;
            background-color: var(--color-brand-2);
            color: var(--color-white-1);

            &:before {
                color: inherit;
                vertical-align: unset;
            }

            @media (min-width: 768px) {
                translate: 50% -50%;
            }
        }

        // If the modal has a title then we want padding
        &:has(.modal-title) {
            padding: var(--gutter-width);
        }
    }

    .modal-title {
        @extend .font-headline-700;
        border-bottom: none;
        padding-bottom: 0;
    }

    .modal-content,
    .modal-footer {
        padding: var(--gutter-width);
    }

    .modal-footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--gutter-width);

        @media (min-width: 768px) {
            display: flex;
            justify-content: flex-end;
        }

        .action-primary,
        .action.primary {
            @extend %btn;
            @extend .btn-thin;
            @extend .btn-brand-2;
            margin: 0 !important;
            order: 1;
        }
        .action-secondary,
        .action.secondary {
            @extend %btn;
            @extend .btn-thin;
            @extend .btn-outline-brand-2;
            margin: 0 !important;
            order: 0;
        }
    }
}
