.orders-list {
    --gutter-width: 30px;

    @media (min-width: 640px) {
        margin-bottom: 60px;
        margin-top: 32px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 90px;
    }

    &:has(+ .pagination) {
        margin-bottom: 30px;
    }

    + .pagination {
        margin-bottom: 60px;
    }
}

.order-accordion {
    position: relative;
    min-width: 0;
    input {
        position: absolute; 
        opacity: 0;
    }
}

.order-accordion:has(input:checked) {
    .order-accordion__indicator span:after {
        display: none;
    }
    .order-accordion__container {
        opacity: 1;
        max-height: 9999999px;
    }
}

.orders-list .accordion-active {
    .order-accordion__label {
        cursor: default;
    }
    .order-accordion__indicator span:after {
        display: none;
    }
    .order-accordion__container {
        opacity: 1;
        max-height: 9999999px;
    }
}

.orders-list-accordions {
    display: grid;
    grid-gap: 16px;
}

.order-accordion__label {
    display: flex;
    grid-gap: var(--gutter-width);
    justify-content: space-between;
    cursor: pointer;
    padding: 24px;
    border: 1px solid var(--color-product-chrome-1);
}

.order-accordion__indicator {
    position: relative;
    display: flex;
    align-items: center;

    span {
        position: relative;
        display: block;
        width: 22px;
        height: 22px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            background-color: var(--color-cyan-1);
            border-radius: 3px;
        }

        &:before {
            top: calc(50% - 1px);
            width: 22px;
            height: 2px;
        }

        &:after {
            left: calc(50% - 1px);
            width: 2px;
            height: 22px;
        }
    }
}

.order-accordion__summary {
    display: grid;
    // grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter-width);
    grid-row-gap: 30px;

    @include media('xs') {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }

    @include media('sm') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 60px;
    }

    @media (min-width: 1200px) {
        grid-column-gap: 90px;
    }
}

.order-accordion__summary-block {
    min-width: 100px;
    max-width: 100px;
    text-wrap: nowrap;
}

.order-accordion__content-title,
.order-accordion__summary-block-key {
    @extend .font-text-500;
    margin-bottom: 0.5em;
    font-size: 12px;
    text-transform: uppercase;
    text-wrap: nowrap;
    letter-spacing: 0.1em;
}

.order-accordion__summary-block-value {
    @extend .font-text-700;
}

.order-accordion__container {
    max-height: 0;
    overflow: hidden;
}

.order-accordion__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: var(--gutter-width);
    padding: 28px;
    background-color: var(--color-white-2);
    border-left: 1px solid var(--color-product-chrome-1);
    border-right: 1px solid var(--color-product-chrome-1);
    border-bottom: 1px solid var(--color-product-chrome-1);

    @include media('sm') {
        flex-wrap: nowrap;
    }
}

.order-accordion__content-items {
    display: grid;
    grid-auto-flow: column;
    grid-gap: var(--gutter-width);
}

.order-accordion__delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: var(--gutter-width);

    @include media('sm') {
        margin-bottom: unset;
        width: unset;
    }
}

.order-accordion__order-actions {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: var(--gutter-width);

    @include media('sm') {
        margin-top: unset;
    }

    a {
        @extend .font-headline-700;
        justify-self: flex-end;
        display: flex;
        align-items: center;
        color: inherit;

        &:hover {
            color: var(--color-brand-2);
        }

        svg {
            width: 21px;
            margin-right: 0.75em;
            color: var(--color-cyan-1);
        }
    }
}

.order-accordion__payment {
    width: 100%;

    @include media('sm') {
        width: 320px;
    }
}

.order-accordion__payment-table {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 10px;
}

.order-accordion__payment-row {
    display: flex;
    justify-content: space-between;
}

.order-accordion__payment-row--bold {
    @extend .font-text-700;
}

.order-accordion__payment-row--light {
    color: var(--color-grey-4);
}

.order-accordion__payment-summary {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-product-chrome-1);
    margin-top: 1em;
    padding-top: 1em;
    font-size: 14px;

    span {
        margin-right: 1em;
    }

    svg {
        width: 26px;
        align-items: center;
        margin-right: 0.5em;
    }

    .card-number {
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;

        &:before {
            position: relative;
            top: -1px;
            content: "●●●●●";
            margin-right: 0.25em;
        }
    }
}

.order-accordion__item-list-container {
    padding: 28px;
    border-left: 1px solid var(--color-product-chrome-1);
    border-right: 1px solid var(--color-product-chrome-1);
    border-bottom: 1px solid var(--color-product-chrome-1);
}

.order-accordion__item-list-titlebar {
    margin-bottom: calc(var(--gutter-width) / 2);
}

.order-accordion__item-list-titlebar,
.order-accordion__item {
    display: grid;
    grid-gap: var(--gutter-width);

    @include media('sm') {
        grid-template-columns: calc(65% - calc(var(--gutter-width) / 2))
                                calc(35% - calc(var(--gutter-width) / 2));
    }
}

.order-accordion__item-order-quantity,
.order-accordion__item-order-price {
    align-items: center;
    @media (min-width: 640px) {
        align-items: unset;
    }
    span {
        min-width: 100px;
        @media (min-width: 640px) {
            min-width: unset;
        }
    }
}

.order-accordion__item-list-title,
.order-accordion__item-list-quantity,
.order-accordion__item-list-price {
    @extend .font-text-500;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
}

.order-accordion__item-order-quantity {
    justify-content: center;
}

.order-accordion__item-order-price {
    justify-content: flex-end;
}

.order-accordion__item-order-quantity,
.order-accordion__item-order-price {
    display: flex;
    // justify-content: space-between;
    grid-gap: var(--gutter-width);

    //@media (min-width: 768px) {
    //    justify-content: center;
    //}
}

.order-accordion__item-order-price .price-including-tax, .order-accordion__item-order-price .price-including-tax span {
    display: block;
}

.order-accordion__item-list {
    display: grid;
    grid-gap: 16px;
}

.order-accordion__item {
    + .order-accordion__item {
        padding-top: 16px;
        border-top: 1px solid var(--color-product-chrome-1);
    }
}

.order-accordion__item-content {
    display: grid;
    align-items: center;
    // grid-template-columns: 100px auto;
    grid-gap: var(--gutter-width);
}

.order-accordion__item-details {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 4px;
}

.order-accordion__item-title {
    @extend .font-headline-700;
}

.order-accordion__item-finish {
    font-size: 14px;
}

.order-accordion__item-list-order {
    display: none;

    @include media('sm') {
        display: grid;
    }
}

.order-accordion__item-list-order,
.order-accordion__item__order {
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(var(--gutter-width) / 2);

    @media (min-width: 640px) {
        grid-gap: var(--gutter-width);

        :first-child {
            text-align: center;
        }

        :last-child {
            text-align: right;
        }
    }
}

.order-accordion__item__order {
    display: flex;
    flex-direction: column; 
    align-items: flex-start;

    @media (min-width: 640px) {
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
    }

    span {
        @extend .font-text-500;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @media (min-width: 640px) {
            margin-right: 1em;
        }
    }

    .cart-price, .price, .price-including-tax {
        margin: 0;
    }

    @include media('sm') {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        align-items: unset;
        justify-content: unset;

        > * {
            min-width: 0;
        }

        span {
            display: none;
        }
    }
}
