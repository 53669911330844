
.downloads-table a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-grey-2);
    border-top: 1px solid var(--color-white-2);
    padding: 10px 0;
    transition: color 150ms ease-in;
}

.downloads-table a span {
    display: flex;
    align-items: center;
}

.download-item__icon svg {
    width: 16px;
    color: var(--color-cyan-1);
}

.download-item__label svg {
    width: 33px;
}

.downloads-table a:hover {
    color: var(--color-cyan-1);
}
