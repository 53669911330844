.actions-tab {
    --h-padding: var(--gutter-width);
    --icon-size: 22px;
    --offset: calc(var(--icon-size) + calc(var(--h-padding) * 2));
    position: fixed;
    right: 0;
    top: calc(100% - var(--offset) * 4);
    z-index: 100;
    translate: calc(100% - var(--offset)) 0;

    @media (min-width: 1024px) {
        --icon-size: 28px;
        top: 35%;
    }
}

a.tab-holder {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: var(--gutter-width);
    padding: calc(var(--gutter-width) / 2) var(--gutter-width);
    background-color: var(--color-white-1);
    border-top: 1px solid transparent;
    border-left: 1px solid var(--color-brand-2);
    border-bottom: 1px solid transparent;
    color: var(--color-brand-2);
    transition:
        translate 100ms ease-in,
        background-color 100ms ease-in,
        color 100ms ease-in;

    &:first-child {
        border-top: 1px solid var(--color-brand-2);
    }

    &:last-child {
        border-bottom: 1px solid var(--color-brand-2);
    }

    + .tab-holder {
        top: -1px;
    }

    svg {
        width: var(--icon-size);
    }

    &:hover {
        background-color: var(--color-brand-2);
        color: var(--color-white-1);
        translate: calc(-100% + var(--offset)) 0;

        &:first-child {
            border-bottom: 1px solid var(--color-brand-2);
        }

        &:last-child {
            border-top: 1px solid var(--color-brand-2);
        }
    }
}

// .compare-tag {
//     position: fixed;
//     right: 0;
//     top: 50%;
//     z-index: 100;
//     display: flex;
//     width: 60px;
//     height: 60px;
//     border: 1px solid var(--color-brand-2);
//     background-color: var(--color-white-1);
//     border-radius: 50% 0px 0px 50%;
//     translate: 7px 0;
//     color: var(--color-brand-2);
//     transition:
//         translate 100ms ease-in,
//         color 100ms ease-in,
//         background-color 100ms ease-in;
//
//     svg {
//         max-width: 50%;
//         margin: 0 auto;
//         color: inherit;
//     }
//
//     &:hover {
//         translate: 1px 0;
//         background-color: var(--color-brand-2);
//         color: var(--color-white-1);
//     }
// }
