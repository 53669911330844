
.titlebar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter-width) * 2);
}

.titlebar__title {
    @extend .font-headline-700;
    font-size: 20px;
}

.titlebar__actionbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: var(--gutter-width);
    width: 100%;
    margin-top: var(--gutter-width);

    @include media('sm') {
        width: unset;
        margin-top: unset;
    }

    .search-form {
        width: 100%;

        @include media('sm') {
            min-width: 355px;
        }
    }
}

.titlebar--large {
    .titlebar__title {
        font-size: 30px;
    }
}

.titlebar--flip {
    .titlebar__title {
        order: 2;

        @include media('sm') {
            order: unset;
        }
    }
    .titlebar__actionbar {
        margin-top: 0;
        margin-bottom: var(--gutter-width);

        @include media('sm') {
            margin-top: unset;
            margin-bottom: unset;
        }
    }
}
