
.pdf-viewer {
    width: 100%;
    height: 50vh;
    padding-bottom: 30px;

    @media (min-width:1024px) {
        height: 100vh;
        padding-bottom: 60px;
    }

    @media (min-width:1440px) {
        padding-bottom: 120px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    iframe #publication #content #publication_wrapper {
        height: auto !important;
    }
}
