
.pagination {
    display: flex;

    .current {
        background-color: var(--color-white-2);
    }

    a, span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        min-width: 32px;
        font-size: 14px;
        padding: 12px;
        color: var(--color-black-1);
    }

    a:hover {
        background-color: var(--color-cyan-1);
        color: var(--color-white-1);
    }
}
