
.basic-hero {
    text-align: center;
    width: calc(100% - calc(var(--gutter-width) * 2));
    margin: 0 auto;
    color: var(--color-grey-1);
    padding-top: 30px;
    padding-bottom: 30px;

    @include media('sm') {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @include media('md') {
        width: calc(70% - var(--gutter-width));
        padding-top: 90px;
        padding-bottom: 90px;
    }

    @include media('lg') {
        width: calc(50% - var(--gutter-width));
        padding-top: 100px;
        padding-bottom: 120px;
    }
}

h1.basic-hero__title {
    @extend .font-headline-700;
    margin-bottom: 0;
    font-size: 28px;

    @media (min-width: 768px) {
        font-size: 30px;
    }

    @media (min-width: 1024px) {
        font-size: 40px;
    }

    @media (min-width: 1480px) {
        font-size: 56px;
    }
}

.basic-hero__description {
    font-size: 16px;
    line-height: 30px;
    margin-top: 1.4em;;

    @include media('sm') {
        font-size: 18px;
    }

    @include media('md') {
        font-size: 20px;
    }
}

@include media('lg') {
    .page-layout-planner-page {
        .basic-hero {
            margin-bottom: -30px;
        }
    }
}
