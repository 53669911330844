
.jobs-titlebar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    @include media('md') {
        margin-bottom: 48px;
    }
}

.jobs-titlebar__title {
    @extend .font-headline-700;
    font-size: 26px;
    width: 100%;
    margin-bottom: .5em;
    color: var(--color-grey-2);

    @include media('sm') {
        font-size: 28px;
    }
    
    @include media('md') {
        width: auto;
        margin-bottom: unset;
        font-size: 30px;
    }
}

.jobs-titlebar__actions {
    display: flex;
    align-items: center;
}

.jobs-titlebar__actions-title {
    font-weight: 700;
    margin-right: 1em;
}

.jobs-titlebar__actions-filters {
    display: flex;
}
