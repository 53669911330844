.page-title {
    padding-top: 30px;
    padding-bottom: 32px;
    position: relative;

    .breadcrumbs-wrapper {
        position: absolute;
        top: 32px;
    }

    h1 {
        margin: 0;
        @extend .font-headline-700;
        font-size: 36px;
        color: var(--color-grey-1);

        @include media('md') {
            font-size: 40px;
        }

        @include media('lg') {
            font-size: 48px;
        }

    }

    @include media('sm') {
        padding-top: 60px;
    }

    @include media('md') {
        padding-top: 75px;
    }

    &.page-title-no-padding {
        padding: 0;
    }
}

.page-title-medium {
    h1 {
        @extend .font-headline-700;
        font-size: 30px;
        color: var(--color-grey-1);
    }
}

.page-title.product, {
    padding-top: 0;

    h1 {
        display: none;

        @include media('sm') {
            display: block;
            font-size: 36px;
        }

        @include media('md') {
            font-size: 40px;
        }

        @include media('lg') {
            font-size: 48px;
        }
    }
}

.page-title__brand {
    padding: 0;
    display: none;

    @include media('sm') {
        display: block;
    }

    h2 {
        @extend .font-headline-700;
        color: var(--color-grey-1);
    }
}

.page-title:has(.breadcrumbs-wrapper) h1 {
    margin-top: 50px;

    @include media('sm') {
        margin-top: 30px;
    }
}

