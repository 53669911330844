.hero-slider-wrapper:hover .hero-slider-controls {
    opacity: 1;
}

.hero-slider-controls {
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: calc(50% - var(--control-size));
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 calc(var(--control-size) / 2);
    transform: translateY(-50%);
    transition: opacity 100ms ease-in;

    @media (touch: none) {
        opacity: 0;
    }

    button {
        display: block;
        width: var(--control-size);
        height: var(--control-size);
        color: vaR(--color-white-1);
        border-radius: 999px;
        background-color: transparent;
        border: 1px solid var(--color-white-1);
        padding: 0;
        backdrop-filter: blur(2px) grayscale(100%) brightness(80%);
        cursor: pointer;
        transition: backdrop-filter 100ms ease-in;

        svg {
            max-width: calc(50%);
        }

        &:hover {
            backdrop-filter: blur(2px) grayscale(100%) brightness(50%);
        }
    }
}
