.page.messages .messages:has(div) {
    margin-top: 10px;
}

.message {

    &.success, &.error, &.notice, &.info {
        margin: 10px 0;
        padding: 18px 25px 18px 30px;
        display: block;
        font-size: 1em;
        // padding-left: 0;
        position: relative;

        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            grid-gap: 12px;
            align-items: center;

            @media (min-width: 640px) {
                grid-gap: unset;
                text-align: unset;
                justify-content: center;
            }
        }

        a {
            display: inline-block;

            @media (min-width: 640px) {
                margin-left: .3em;
                margin-right: .3em;
            }
        }

        > *:first-child:before {
            display: none;
        }
    }

    &.notice, &.info {
        border: none;
        border-radius: 0;
        background: #fff3cd;
    }

    &.success {
        background: #e5efe5;

       /* > *:first-child:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 24px;
            line-height: 24px;
            color: #006400;
            content: '\e60e';
            font-family: 'luma-icons';
            margin: unset;
            vertical-align: middle;
            display: block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            width: 45px;
            position: unset;
            text-align: center;
        }*/
    }

    &.message-thin {
        padding: 15px 25px 15px 17px;
    }
}
