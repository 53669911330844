
.product-content {
    .product-logo {
        margin-bottom: 10px;
    }

    .product-title,
    .product-logo {
        display: none;

        @include media('sm') {
            display: block;
        }
    }
}

.product-title-mobile {
    display: grid;
    //grid-gap: var(--gutter-half-width);
    margin-bottom: 30px;
    //grid-template-columns: 1fr;

    /*.product-title {
        padding-right: 30px;
        order: 2;
    }*/

    @include media('sm') {
        display: none;
    }

    @media (min-width: 768px) {
        grid-template-columns: 80% 20%;
    }

    .product-logo {
        display: block;
        order: 1;
        margin-bottom: 10px;
        //text-align: right;
    }

    .page-title {
        padding-top: 0;
        padding-bottom: 0;
        order: 2;
    }

    .page-title__brand {
        display: block;

        @include media('sm') {
            display: none;
        }

        h2 {
            font-size: 16px;

            @media (min-width: 480px) {
                font-size: 20px;
            }
        }
    }

    span {
        @extend .font-headline-700;
        font-size: 20px;
        color: var(--color-grey-1);

        @media (min-width: 480px) {
            font-size: 30px;
        }

        @media (min-width: 640px) {
            font-size: 36px;
        }
    }
}

.product-title {
    @extend .font-headline-700;
    font-size: 20px;
    color: var(--color-grey-2);

    @include media('xs') {
        font-size: 28px;
    }

    @include media('sm') {
        font-size: 30px;
        margin-top: 20px;
    }

    @include media('md') {
        font-size: 38px;
    }

    @include media('lg') {
        font-size: 40px;
    }
}
