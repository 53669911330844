
.search-dialog {
    z-index: 300;
    top: 0;
    width: 100%;
    border: none;
    padding: 0;
    background-color: var(--color-brand-2);

    &[open] {
        top: var(--header-height);
        animation: 500ms fly-in-down normal forwards;
    }

    &.hide {
        animation: 500ms fly-out-up normal forwards;
    }
}

.search-dialog__content {
    position: relative;
}

.search-dialog__close-wrapper {
    position: absolute;
    top: 0;
    right: 24px;
    height: 100%;
    display: flex;
    align-items: center;

    button {
        width: 24px;
        height: 24px;
        border: none;
        background: transparent;
        cursor: pointer;

        svg {
            width: 18px;
            color: var(--color-white-1);
        }
    }
}

.search-dialog .search-form {
    max-width: 600px;
    padding: 16px;
    margin: 0 auto;

    input {
        background-color: var(--color-white-1);
    }
}
