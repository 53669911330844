
.menu-link {
    @extend .font-text-500;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3A3A3A;
    line-height: 1;
    font-size: 16px;
    font-weight: 500;

    @include media('md') {
        font-size: 18px;
    }
}

.menu-link svg {
    display: block;
    width: 11px;
    color: var(--color-cyan-1);
    margin-left: 15px;
    transform: translateY(2px);
}
