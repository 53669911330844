
.compare-titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid var(--color-product-chrome-1);

    h1 {
        @extend .font-headline-700;
        font-size: 48px;
        margin-bottom: 0;
    }

    a {
        transform: translateY(4px);
        color: var(--color-cyan-1);
        font-size: 14px;
    }
}
