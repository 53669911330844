
.video-dialog {
    position: fixed; 
    z-index: 999;
    padding: 0;
    border: none;
    overflow: visible;
}

.video-dialog::backdrop {
    backdrop-filter: blur(5px);
}

.video-dialog-content {
    position: relative;
    width: 80vw;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(calc(9 / 16) * 100%);
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        transform: translate(12px, 12px);
    }
}

.video-dialog__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    background-color: var(--color-brand-2);
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: var(--color-white-1);
        width: 60%;
    }

    &:hover,
    &:focus {
        background-color: var(--color-brand-2);
        border: none;
    }
}
