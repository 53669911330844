
.article-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    .share-widget {
        width: 100%;
        margin-bottom: 2em;

        @include media('md') {
            width: auto;
            margin-bottom: unset;
        }
    }

    @include media('md') {
        margin-top: 48px;
    }
}
