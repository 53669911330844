
.product-card--wishlist {
    .product-card__heart {
        color: var(--color-brand-2);
    }
}

.product-image-photo {
    // scale: 1.2; // scale images so we don't get white bars
}

.product-card {
    color: var(--color-text-primary);

    &:hover {
        .product-card__heart {
            opacity: 1;
            transform: scale(1);
        }

        .product-card__image {
            opacity: 0.8;
        }

        .product-card__title {
            color: var(--color-brand-2-dark);
        }
    }

}

.product-image-wrapper {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(calc(1 / 1) * 100%);
    }
    > img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .logo-placeholder {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        filter: grayscale(90%);
        opacity: .25;

        svg {
            height: 70%;
        }
    }

    &.image-failed {
        img {
            display: none;
        }

        .logo-placeholder {
            display: flex;
        }
    }
}

.product-card__details {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.product-card__content {
    flex-grow: 1;
}

.product-card__heart {
    z-index: 76;
    position: absolute;
    top: 1em;
    right: 1em;
    width: 32px;
    color: transparent;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 100ms ease-in,
                transform 100ms ease-in;
    --stroke-color: var(--color-brand-2);

    @media (hover: none) {
        opacity: 1;
    }
}

.product-image-container {
    display: block;
    width: unset !important;
    height: unset !important;
    max-width: 100%;
}

.product-card__image picture .logo-placeholder {
    display: none;
}

.product-card__image picture.image-failed {
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(calc(6 / 7) * 100% + 2px);
    }

    .logo-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .25;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        filter: grayscale(90%);

        svg {
            height: 70%;
        }
    }

    img {
        display: none;
    }
}

.product-card__image picture {
    position: relative;
    /*
    > img {
        position: absolute;
        top: 0;
        right: 0;
    }*/
}

.product-card__image {
    border: 1px solid var(--color-border-primary);
    transition: opacity 100ms ease-in;
    z-index: 50;

    img {
        width: 100%;
    }
}

.product-card__image .cross {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
}

.product-card__colors {
    display: flex;
    align-items: center;
    margin-top: .5em;
}

.product-card__colors-more {
    font-size: 12px;
    display: flex;
    align-items: baseline;
    margin-left: .5em;
    color: var(--color-text-primary);

    svg {
        width: .75em;
    }
}

.product-card__dot {
    --color: var(--color-white-2);
    --border-color: var(--color-grey-3);
    border: 1px solid var(--border-color);
    display: block;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    background-color: var(--color);
    z-index: 50;

    + .product-card__dot {
        margin-left: -8px;
    }

    &.product-card__image {
        background-size: cover;
        background-position: center;
        background-color: transparent;
    }
}

.product-card__description {
    display: none;
}

.product-card__title, .product-card__brand {
    margin-top: 8px;
    color: var(--color-text-primary);
    transition: color 100ms ease-in;

    a {
        color: inherit;
    }
}

.product-card__brand {
    @extend .font-headline-500;
}

.product-card__price {
    @extend .font-headline-700;
    margin-top: 8px;
    font-size: 18px;
    color: var(--color-text-primary);

    @include media('md') {
        font-size: 18px;
    }
}

.product-card__actions  {
    margin-top: 1em;
}

/*.product-card__list-price {
    display: none;
}*/

.product-card .custom-checkbox {
    font-size: 14px;
}

.product-card__actions .simple-link {
    margin-left: 1em;
}

.product-card__list-price-current {
    @extend .font-headline-700;
    color: var(--color-text-primary);
    font-size: 20px;
}

.product-card__list-price-saving {
    font-size: 14px;
    color: var(--color-text-primary);
    margin-top: .75em;
}

.product-card__sale,
.product-card__list-price-sale {
    z-index: 76;
    @extend .font-pt-700;
    display: inline-flex;
    text-transform: uppercase;
    background-color: var(--color-brand-2);
    color: var(--color-white-1);
    padding: .75em 1em;
    line-height: 1;
    margin-top: 2em;
}

.product-card__sale {
    position: absolute;
    top: .5em;
    left: .5em;
    margin-top: 0;

    @include media('lg') {
        top: 1em;
        left: 1em;
    }
}

.product-card__details .custom-checkbox {
    color: var(--color-text-primary);
    margin-top: 1em;
}
.product-list-option__checkbox {
    position: absolute;
    opacity: 0;
}

//.product-list-option__checkbox:checked:nth-of-type(1) ~ .container {
.product-grid.list {

    // This makes sure the price and title line up
    .product-card__list-price {
        display: none;
        @media (min-width: 640px) {
            display: block;
        }
        .product-card__brand {
            opacity: 0;
        }
    }
    .product-card__sale {
        display: none;
    }

    .product-card__heart {
        right: auto;
        left: .5em;
        top: .5em;
        width: 24px;
    }

    .product-card__details {
        display: grid;
        grid-template-columns: 124px 1fr;
        grid-gap: var(--gutter-width);

        @media (min-width: 640px) {
            grid-template-columns: 170px 1fr 120px;
        }
    }

    .product-card__content {
        display: flex;
        flex-direction: column;

        .custom-checkbox {
            order: 3;
        }

        .product-card__price {
            display: block;
            @media (min-width: 640px) {
                display: none;
            }
        }
    }

    .product-card__colors {
        order: 2;
        marign-top: 1em;
    }

    /*.product-card__price {
        display: none;
    }*/

    .product-card__price {
        margin-top: 0;
    }

    .product-card__title {
        @extend .font-headline-700;
        font-size: 20px;
        margin-top: 0;
    }

    .product-card__description {
        display: block;
        color: var(--color-text-primary);
        margin-top: .5em;
        width: 95%;
    }

    /*.product-card__list-price {
        display: block;
    }*/
}
.product-list-option__checkbox:nth-of-type(2):checked ~ .container {
    .product-grid .product-card__details {
        // background: red;
    }
}
