
.cross {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-cyan-1);
    color: var(--color-white-1);
    border: none;
    padding: 0;

    svg {
        width: 12px;
    }
}
