.sidebar-block {
    position: relative;
}

.sidebar-block > input {
    position: absolute;
    opacity: 0;
}

.sidebar-block > input:checked {
    ~ label svg {
        transform: rotate(180deg);
    }

    ~ .sidebar-block__list {
        max-height: 9999px;
    }
}

.sidebar-block__titlebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-product-chrome-1);
    pointer-events: none;
    transition: opacity 100ms ease-in;

    svg {
        display: none;
        width: 14px;
        color: var(--color-cyan-1);
    }

    &:hover {
        opacity: 0.8;
    }
}

.sidebar-block__title {
    @extend .font-headline-700;
    padding: 16px 0;
    font-size: 16px;
    line-height: 1.5;
}

.sidebar-item--active {
    @extend .font-pt-500;
    padding-left: 1.5em;
    font-size: 16px;
}

.sidebar-block__list .sidebar-block__list {
    margin-left: 2.5em;
}

.sidebar-block__list {
    display: flex;
    flex-direction: column;

    h3, h4, h5 {
        font-size: unset;
        font-family: var(--font-text-400);

        &:last-child {
            margin-bottom: 21px;
        }
    }

    a,
    label {
        color: inherit;
        font-size: 14px;
        margin: 7px 0;
    }

    .sidebar-item--active {
        font-size: 16px;
    }

    a {
        //display: flex;
        display: block;
        align-items: baseline;
        transition: color 100ms ease-in;

        svg {
            width: 1em;
            transform: translateY(1px);
            margin-right: 0.5em;
            color: var(--color-cyan-1);
        }

        &:hover {
            color: var(--color-brand-2);
        }
    }

    label div {
        width: 20px;
        height: 20px;
        margin-right: 1em;
        border: 1px solid currentColor;
        color: var(--color-grey-1);
        border-radius: 2px;

        svg {
            transform: rotate(5deg);
            opacity: 0;
            transition: 100ms transform ease-in, 100ms opacity ease-in;
        }
    }

    label input {
        position: absolute;
        opacity: 0;
    }

    label input:checked ~ div {
        background-color: var(--color-cyan-1);
        border-color: var(--color-cyan-1);
    }

    label input:checked ~ div svg {
        opacity: 1;
        transform: rotate(0);
        color: var(--color-white-1);
    }
}

.sidebar-block--interactive {
    .sidebar-block__titlebar {
        pointer-events: all;
        cursor: pointer;

        svg {
            display: block;
        }
    }

    .sidebar-block__list {
        max-height: 0;
        overflow: hidden;
    }
}

.sidebar-block__list .swatch-attribute-options {
    display: flex;
    padding: 0 3px;
    flex-wrap: wrap;
    grid-gap: .5em;

    .swatch-option {
        margin: 0;
    }

    a.swatch-option-link-layered div {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: inherit;
        background-color: currentColor;
        border: 2px solid var(--color-grey-3);
        transition: scale 150ms ease-in,
            border-color 150ms ease-in,
            box-shadow 150ms ease-in;

        &:hover {
            scale: 1.05;
        }

        &.selected {
            border: 2px solid var(--color-cyan-1);
            box-shadow: inset 0px 0px 0px 2px var(--color-white-1);
        }

        &.image {
            background-size: cover;
            background-position: center;
            background-color: transparent;
        }
    }
}

#sidebar-show-more {
    opacity: 0;
    position: absolute;
}

#sidebar-show-more:checked ~ .sidebar-show-more__trigger {
    .more {
        display: none;
    }
    .less {
        display: block;
    }
}

#sidebar-show-more:checked ~ .sidebar-show-more__list {
    max-height: 999px;
}

.sidebar-show-more__list {
    max-height: 0;
    overflow: hidden;
}

.sidebar-show-more__trigger {
    @extend .font-headline-700;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    transition: color 100ms ease-in;

    .less {
        display: none;
    }

    &:hover {
        color: var(--color-brand-2);
    }

    svg {
        margin-right: 0.25em;
        width: 1em;
        transform: translateY(1px);
        color: var(--color-cyan-1);
    }
}
