
.hero-slider-navigation {
    position: absolute;
    z-index: 100;
    width: 100%;
    bottom: 48px;
    display: flex;
    justify-content: center;

    div {
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background-color: var(--color-white-1);
        opacity: .3;
        transition: opacity 100ms ease-in;
        cursor: pointer;

        &:hover {
            opacity: .5;
        }

        + div {
            margin-left: 10px;
        }

        &.tns-nav-active {
            opacity: 1;
        }
    }
}
