:root {
    --scroll-top: 0px;
    --header-height: 80px;

    @include media('sm') {
        --header-height: 110px;
    }
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    text-rendering: geometricPrecision;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

a:visited, .alink:visited {
    color: inherit;
    text-decoration: none;
}

a:hover, .alink:hover {
    text-decoration: none;
}

html:has(body.noscroll) {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    body {
        margin-top: calc(var(--scroll-top));
    }
}
