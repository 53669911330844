
.breadcrumbs-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;

    a, span {
        white-space: nowrap;
        color: var(--color-text-primary);
        display: flex;
        align-items: center;
        font-size: 14px;
        transition: color 100ms ease-in;

        &:not(:last-child):after {
            content: '/';
            padding: 0 .5em;
            color: var(--color-border-primary);
        }

        &:hover {
            color: var(--color-brand-2);
        }
    }
}
