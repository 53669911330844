
.product-slider {
    position: relative;
}

.product-slider:hover .product-slider-controls {
    opacity: 1;
}

.product-slider-controls {
    position: absolute;
    top: 290px; right: var(--gutter-width);
    z-index: 50;
    justify-content: space-between;
    width: calc(100% - var(--gutter-width) * 2);
    transition: opacity 150ms ease-in;
    opacity: 0;
    display: none;

    @include media('sm') {
        display: flex;
    }

    button {
        cursor: pointer;
        width: 48px;
        height: 48px;
        padding: 0;
        color: red;
        background-color: transparent;
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 48px;
    }

    svg {
        width: 16px;
    }
}

@keyframes pulse {
    0%{background-position:50% 0%}
    50%{background-position:50% 100%}
    100%{background-position:50% 0%}
}

.product-slider-images {
    position: relative;
    overflow: hidden;
    height: 550px;
    background: linear-gradient(0deg, #e4e4e4, #FFFFFF);
    background-size: 150% 200%;
    animation: pulse 1s ease infinite;

    img {
        opacity: 0;
    }
}

.product-image-wrap {
    position: absolute;
    top: 0;
    text-align: center;
}

.product-image {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(calc(617 / 852) * 100%);
    }

    > img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .logo-placeholder {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        filter: grayscale(90%);
        opacity: .25;

        svg {
            height: 70%;
        }
    }

    &.image-failed  {

        img {
            display: none;
        }

        .logo-placeholder {
            display: flex;
        }
    }
}

.tns-outer {
    .product-slider-images {
        height: unset;
        opacity: unset;
        background: unset;
        background-size: unset;
        animation: unset;

        img {
            opacity: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .product-image-wrap {
        position: unset;
        top: unset;
    }
    .product-slider-thumbnails {
        height: unset;
        opacity: unset;
    }
    .product-image {
        //background-color: var(--color-white-2);
        border: 1px solid var(--color-white-2);
        opacity: unset;
    }
}

.product-image {
    opacity: 0;
    // border: 1px solid var(--color-white-2);
    // background-color: var(--color-white-2);

    img {
        display: block;
        margin: 0 auto;
    }
}

.product-image-caption {
    padding: 1em;
    font-size: 14px;
}

.product-slider-thumbnail img {
    display: block;
}

.product-slider-thumbnails {
    opacity: 0;
    height: 80px;
}

.product-slider-thumbnails-wrap {
    position: relative;
    width: 50%;
    margin: 0 auto;
    margin-top: 1em;

    @include media('md') {
    }

    @include media('lg') {
        width: 50%;
    }
}


.product-images {

    .product-slider:hover .product-slider-thumbnails-controls {
        opacity: 1;
    }

    .product-slider:hover .product-slider-thumbnails-controls {
        opacity: 1;
    }

}

.product-slider-thumbnails-controls {
    --top: 0px;
    position: absolute;
    top: var(--top);
    right: calc(-48px - var(--gutter-width));
    z-index: 80;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    width: calc(100% + calc(48px * 2) + calc(var(--gutter-width) * 2));
    transition: opacity 150ms ease-in;
    opacity: 0;
    pointer-events: none;

    button {
        pointer-events: all;
        cursor: pointer;
        width: 48px;
        height: 48px;
        padding: 0;
        background-color: transparent;
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 48px;
    }

    svg {
        width: 16px;
    }
}

.product-slider__thumbnail {
    display: block;
    cursor: pointer;

    &:hover {
        opacity: .75;
    }

    > div {
        width: auto;
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 4px;
        overflow: hidden;
        position: relative;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: calc(calc(1 / 1) * 100%);
        }
        > img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .logo-placeholder {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            filter: grayscale(90%);
            opacity: .25;

            svg {
                height: 70%;
            }
        }

        &.image-failed {
            img {
                display: none;
            }

            .logo-placeholder {
                display: flex;
            }
        }
    }

    &.tns-nav-active {
        > div {
            border-color: var(--color-brand-2);
        }
    }
}
