
.shipping-form {
    margin-bottom: 30px;

    @include media('sm') {
        margin-bottom: 60px;
    }

    @include media('md') {
        margin-bottom: 90px;
    }

    .form-section {
        margin-top: 60px;
    }
}

.form-tab-section {
    margin-top: 60px;

    input[type="radio"] {
        opacity: 0;
        position: absolute;
    }

    .form-tab-section__form {
        display: none;
    }

    @for $i from 1 through 2 {
        input[type="radio"]:checked:nth-child(#{$i}) 
        ~ .form-section-radioset label:nth-child(#{$i}) {
            background-color: var(--color-cyan-1);
            color: var(--color-white-1);
            border-color: var(--color-cyan-1);

            span {
                border-color: var(--color-white-1);

                &:before {
                    background-color: var(--color-white-1);
                }
            }
        }
        input[type="radio"]:checked:nth-child(#{$i}) ~ 
        .form-tab-section__content .form-tab-section__form:nth-child(#{$i}) {
            display: block;
        }
    }
}

.form-section-radioset {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px; 

    p {
        transform: translateY(-2px);
    }

    label {
        @extend .font-headline-700;
        display: flex;
        align-items: center;
        border: 1px solid var(--color-grey-3);
        height: 48px;
        color: var(--color-grey-2);
        padding: 0 16px;
    }

    span {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border:  1px solid var(--color-grey-3);
        margin-right: .5em;

        &:before {
            content: '';
            position: absolute;
            top: 4px; left: 4px;
            background-color: transparent;
            width: 10px; height: 10px;
            border-radius: 10px;
        }
    }
}

.form-tab-section__form-title {
    @extend .font-headline-700;
    color: var(--color-cyan-1);
    font-size: 20px;
    margin-bottom: 1em;
} 

.form-tab-section__form-subtitle {
    @extend .font-headline-700;
}

.form-tab-section__form-description {
    margin-top: .75em;
    margin-bottom: 1.5em;
}

.form-tab-section__content {
    padding: 16px;
    border:  1px solid var(--color-grey-3);
    margin-top: 16px;
}

