
.video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-size: cover;
    background-position: center;

    &[data-video] {
        cursor: pointer;
    }
}

.video-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(72, 72, 72, .6);
    width: 118px;
    height: 118px;
    border-radius: 999px;

    svg {
        width: 35px;
        transform: translateX(15%);
        color: var(--color-white-1);
    }
}
