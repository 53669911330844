
.product-lifestyle {
    display: none;
    grid-gap: var(--gutter-width);
    margin-top: calc(var(--gutter-width) * 3);
    grid-template-columns: 1fr 1fr;

    > *:nth-child(2n-1) {
        grid-column: span 2;
    }

    > *:nth-child(3n) {
        grid-column: span 1;
    }

    > *:nth-child(4n) {
        grid-column: span 2;
    }

    @include media('sm') {
        display: grid;
    }

    img {
        width: 100%;
    }
}
