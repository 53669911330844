
.search-hero {
    @extend .banner-hero;
}

.search-hero--center
.search-hero__container-wrapper .container {
    justify-content: center;

    .search-hero__content-wrapper {
        justify-content: center;
    }

    .search-hero__content {
        text-align: center;
    }
}

.search-hero__container-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, .5);
}

.search-hero__container-wrapper .container {
    height: 100%;
    display: flex;
}

.search-hero__content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-hero__content {
    color: var(--color-white-1);
    transform: translateY(calc( -1 * var(--gutter-width)));
}

.search-hero__title {
    @extend .banner-hero__title;
}

.search-hero__description {
    @extend .banner-hero__description;
}

.search-hero .search-form {
    margin-top: 32px;
    position: relative;
}
