
.product-details {
    display: block;
    grid-gap: var(--gutter-width);
    margin-bottom: 30px;

    @include media('sm') {
        display: grid;
        //grid-template-columns: 50% 50%;
        grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    }

    @include media('lg') {
        grid-gap: 90px;
        padding-right: 60px;
        grid-template-columns: calc(60% - 45px) calc(40% - 45px);
        //grid-template-columns: minmax(60%, 815px) minmax(40%, 520px);
    }
}
