
.contact-section {
    width: 100%;
    display: grid;
    grid-gap: 30px; 
    padding-top: 30px;
    padding-bottom: 30px;

    @include media('xs') {
        padding-top: 60px;
        padding-bottom: 60px;
        grid-gap: 60px; 
    }

    @include media('sm') {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    @include media('md') {
        padding-top: 120px;
        padding-bottom: 120px;
        grid-template-columns: 400px auto;
    }

    @include media('lg') {
        padding-bottom: 180px;
    }

    @include media('xl') {
        grid-gap: 110px; 
    }
}

.contact-content {
    width: 100%;
    max-width: 400px;
}

.contact-form {
    width: 100%;
}

.contact-content__title {
    @extend .font-headline-700;
    font-size: 40px;
    line-height: 50px;
}

.contact-content__description {
    margin-top: 2em;

    p {
        margin-top: 1em;
    }

    a {
        color: var(--color-cyan-1);
        text-decoration: underline; 
    }
}
