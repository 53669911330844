
$column-count: 12;

$gutter-width: 20px;
$gutter-half-width: 12px;
$gutter-compensation: -12px;

$grid-sizes: (
    "xs": 480,
    "sm": 768,
    "md": 1024,
    "lg": 1480,
    "xl": 1600,
    "mx": 1920,
);

// The space outside the .container
$outer-margins: (
    480px: 24px,
    768px: 48px,
    1480px: 60px,
);


@mixin media($name) {
    @media (min-width: map-get($grid-sizes, $name) + 'px') {
        @content;
    }
}
