
.notice-dialog {
    --background-color: var(--color-white-2);
    --border-color: var(--color-border-primary);
    --text-color: var(--color-grey-2);
    position: fixed;
    top: 15vh;
    z-index: 500;
    padding: 0;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.notice-dialog__container {
    position: relative;
    padding: var(--gutter-width) calc(var(--gutter-width) * 2);
}

.notice-dialog__close {
    position: absolute;
    top: -14px;
    right: -14px;
    background-color: var(--color-brand-2);
    border: none;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    cursor: pointer;

    svg {
        width: 16px;
        color: var(--color-white-1);
        pointer-events: none;
    }
}

.notice-dialog--info {
    --background-color: var(--color-info-background);
    --border-color: var(--color-brand-1);
}

.notice-dialog--warning {
    --background-color: var(--color-warning-background);
    --border-color: var(--color-product-gold-1);
}

.notice-dialog--error {
    --background-color: var(--color-error-background);
    --border-color: var(--color-brand-3);
}
