.loading-mask {
}

.loader-outer {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-icon {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        //margin: 8px;
        border: 8px solid var(--color-cyan-1);
        border-radius: 50%;
        animation: loader-icon 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color:  var(--color-cyan-1) transparent transparent transparent;
    }
}

.shipping-main .form-login {
    .loader-outer {
        justify-content: flex-end;
        padding: 42px 10px;
        align-items: flex-start;
    }
    .loader-icon {
        width: 30px;
        height: 30px;

        div {
            width: 22px;
            height: 22px;
            border-width: 4px;
        }
    }
}

.loader-icon div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader-icon div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader-icon div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader-icon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
