
.compare-item__card {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    @include media('xs') {
        padding: 20px 20px;
    }

    @include media('sm') {
        padding: 30px 20px;
    }
}

.compare-labels {
    display: none;

    @include media('lg') {
        display: block;
    }
}

.compare-label {
    @extend .font-headline-700;
    padding: 20px;
    line-height: 20px;

    &:not(:first-child) {
        border-top: 1px solid var(--color-product-chrome-1);
    }
}

.compare-item__label {
    @extend .font-headline-700;
    margin-bottom: .5em;
    display: block;

    @include media('lg') {
        display: none;
    }
}

.compare-item__specs-value {
    a {
        transition: color 100ms ease-in;
        text-decoration: underline;

        &:hover {
            color: var(--color-brand-2)
        }
    }
}

.compare-item__specs {
    display: grid;
    padding: 20px;
    border-top: 1px solid var(--color-product-chrome-1);

    .product-card__price {
        margin-top: 0;
        line-height: 20px;
    }
}

.compare-item__swatches {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 40px));
    grid-gap: 10px;
}

.compare-item__swatch {
    --color: currentColor;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--color);
}

.compare-item__image picture .logo-placeholder {
    display: none;
}

.compare-item__image a:has(.image-failed) {
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: calc(calc(1 / 1) * 100%);
    }
}

.compare-item__image {
    position: relative;
    border: 1px solid var(--color-product-chrome-1);

    .cross {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        transition: background-color 150ms ease-in;

        &:hover {
            color: var(--color-white-1);
            background-color: var(--color-brand-3);
        }
    }

    .item-tag {
        position: absolute;
        top: 14px;
        left: 17px;
    }

    .logo-placeholder {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        filter: grayscale(90%);
        opacity: .25;

        svg {
            height: 70%;
        }
    }

    .image-failed {

        img {
            display: none;
        }

        .logo-placeholder {
            display: flex;
        }
    }
}


.compare-item__title, .compare-item__brand {
    margin-top: 8px;
    color: var(--color-text-primary);
}

.compare-item__brand {
    @extend .font-headline-500;
}

.compare-item__title {
    min-height: 3em;
    max-height: 3em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.compare-item__details {
    flex-grow: 1;
    margin-bottom: 1em;
}

.compare-item__actions {
    a {
        width: 100%;
        white-space: nowrap;
    }
}

.compare-item__actual-price {
    @extend .font-headline-700;
    font-size: 20px;
    margin-top: 1em;
    margin-right: .5em;
}
