
.article-hero {
    position: relative;
    @extend .banner-hero;
}

.article-hero__background {
    position: absolute;
    width: 100%;
    @extend .banner-hero;
}

.article-hero__background-filter {
    width: 100%;
    height: inherit;
    backdrop-filter: blur(10px) brightness(40%);
}

.article-hero__container-wrapper {
    flex: 0 0 100%;
    position: relative;
    color: var(--color-white-1);

    .breadcrumbs-wrapper {
        padding-top: var(--gutter-width);

        a, span {
            color: var(--color-white-1);

            &:not(:last-child):after {
                content: '/';
            }
        }
    }
}

.article-hero__title {
    @extend .font-headline-700;
    font-size: 28px;

    @media (min-width: 768px) {
        font-size: 30px;
    }

    @media (min-width: 1024px) {
        font-size: 40px;
    }

    @media (min-width: 1480px) {
        font-size: 48px;
    }
}

.article-hero__content {
    text-align: center;
    padding-top: 30px;

    @include media('sm') {
        padding-top: 50px;
    }
}

.article-hero__date {
    margin-top: 1em;
}

.article-hero__image {
    margin-top: 45px;

    @include media('sm') {
        margin-top: 85px;
    }
}

