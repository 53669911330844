fieldset hr {
    display: none;
}

.form .field:has( + .form-columns) {
    margin-bottom: var(--gutter-width);
}

.form .form-columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--gutter-width);

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .field,
    .field + .field {
        margin: 0;
    }

    + .field {
        margin-top: var(--gutter-width);
    }
}

.form {
    container-type: inline-size;
    --gutter-width: 24px;
    --border-color: var(--color-border-primary);
    --form-border: 1px solid var(--border-color);

    .address-default {
        margin-top: 1em;
        margin-bottom: 1em;
        font-family: var(--font-text-700);
    }

    fieldset {
        margin: unset;
        padding: unset;
        border: none;
    }

    .form-intro {
        margin-bottom: calc(var(--gutter-width) * 2);
    }

    .form-title {
        @extend .font-headline-700;
        font-size: 30px;
        margin-bottom: var(--gutter-width);
    }

    legend {
        @extend .font-headline-700;
        font-size: 20px;
        margin-bottom: var(--gutter-width);
    }

    fieldset,
    .form-content,
    .form-disclaimer,
    .form-hr,
    .field-group,
    .field {
        + fieldset,
        + .form-actions,
        + .form-content,
        + .form-disclaimer,
        + .field-group,
        + .field {
            margin-top: var(--gutter-width);
        }
    }

    // Fields next to fields without a label need different margin top
    @include media('sm') {
        .field + .field:not(:has(label)) {
            margin-top: calc(1em + 14px);
        }
    }

    .form-content__title,
    .form-disclaimer span {
        @extend .font-headline-700;
    }

    .form-disclaimer {
        font-size: 14px;
    }

    .form-hr {
        border-top: var(--form-border);
        margin-top: var(--gutter-width);
    }

    .field {
        --text-color: var(--color-grey-2);
        --border-color: var(--color-grey-3);
        --background-color: var(--color-white-1);
        position: relative;
        display: flex;
        flex-direction: column;
        color: var(--text-color);

    }

    .field--error {
        --text-color: var(--color-brand-3);
        --border-color: var(--color-brand-3);
    }

    label {
        color: inherit;
        margin-bottom: .5em;
    }

    select,
    input[type="tel"],
    input[type="text"],
    input[type="password"],
    input[type="email"] {
        border: var(--form-border);
        height: 48px;
        padding: 0 1em;
        background-color: var(--background-color);

        &:focus {
            outline: none;
            border-color: var(--color-brand-2);
        }
    }

    .select {
        background-color: var(--background-color);
    }

    // Use required on the select field to get the desired result
    .field select:invalid,
    .field input::placeholder {
        color: var(--color-grey-4);
    }

    textarea {
        min-height: 180px;
        border: 1px solid var(--border-color);
        padding: 1em;

        &:focus {
            outline: none;
            border-color: var(--color-brand-2);
        }
    }

    .field-group {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--gutter-width);

        .field {
            margin-top: 0;

            + .field:not(:not(:has(label))) {
                margin-top: 0;
            }

            @include media('sm') {
                margin-top: unset;
            }
        }
    }

    @include fieldGroupLimiter(2, 280px);
    @include fieldGroupLimiter(3, 200px);

    .field-group--dense {
        display: flex;
    }

}
