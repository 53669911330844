
body {
    @extend .font-text-400;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text-primary);
    font-size: 1.6rem;
}

p, h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: inherit;
    margin: 0;
}

p {
    line-height: 1.5;
}

h1 {
    font-size: 28px;

    @include media('sm') {
        font-size: 30px;
    }

    @include media('md') {
        font-size: 40px;
    }

    @include media('lg') {
        font-size: 56px;
    }
}

h3 {
    @extend .font-headline-700;
}

.link {
    @extend .font-pt-700;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .1em;
    color: var(--color-grey-2);
    padding-bottom: 2px;
    border-bottom: 1px solid var(--color-brand-2);
    transition: color 100ms ease-in;

    &:hover {
        color: var(--color-brand-2);
    }
}

.text-white {
    color: var(--color-white-1);
}

.text-black {
    color: var(--color-black-1);
}

.text-right {
    text-align: right;
}

.content, div[data-content-type='text'], .category-description {

    h2 {
        @extend .font-headline-700;
        font-size: 32px;
        margin-bottom: 0.5em;

        @include media('sm') {
            font-size: 36px;
        }

        @include media('md') {
            font-size: 40px;
        }
    }

    h3 {
        font-size: 30px;
        margin-bottom: .5em;
    }

    p {
        + p {
            margin-top: 1em;
        }
    }

    img {
        margin-top: 2em;
    }

    .caption {
        font-size: 14px;
        margin-top: 1em;
    }

    strong {
        @extend .font-headline-500;
    }

    blockquote {
        font-size: 20px;
        color: var(--color-cyan-1);
        border-left: 1px solid var(--color-cyan-1);
        margin-left: 0;
        margin-right: 0;
        margin-top: 2em;
        margin-bottom: 2em;
        padding-left: 30px;
        font-style: normal;
    }

    ol {
        margin-top: 1em;

        li {
            line-height: 1.6;
        }
    }

    ul {
        margin-top: 1em;
        padding: 0;

        li {
            position: relative;
            list-style: none;
            padding-left: 1.5em;

            &:before {
                position: absolute;
                top: 0; left: 0;
                transform: translateY(.5em) translateX(100%);
                display: block;
                content: '';
                width: 8px;
                height: 8px;
                background-color: currentColor;
                border-radius: 10px;
            }
        }
    }
}

.content--title {
    font-size: 20px;
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.main [data-appearance='contained'] {
    @extend .container-max;
}
.main [data-content-type='row'] {
    @extend .row;
}
.main [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    @extend .col-12;
    margin-bottom: 60px;
}
