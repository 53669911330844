
.wishlist-empty {
    background-color: var(--color-white-2);
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;

    svg {
        width: 64px;
        margin-bottom: 30px;
    }

    @include media('xs') {
        padding: 40px;
        margin-bottom: 60px;
    }

    @include media('sm') {
        padding: 80px;
        margin-bottom: 90px;
    }

    @include media('md') {
        margin-bottom: 120px;
    }
}

.wishlist-empty__title {
    @extend .font-headline-700;
    color: var(--color-cyan-1);
    font-size: 20px;
    margin-bottom: .75em;
}

.wishlist-empty__message {
    max-width: 620px;
    margin: 0 auto;
    margin-bottom: 1.5em;
}
