.customer-account-create,
.customer-account-createpassword,
.customer-account-forgotpassword {
    .page-title h1 {
        text-align: center;
        font-size: 30px;
    }
}

.form.password.reset,
.form.password.forget,
.form-create-account {
    margin-bottom: 60px;
    max-width: 855px;
    margin-left: auto;
    margin-right: auto;

    fieldset {
        border: 1px solid var(--color-product-chrome-1);
        padding: var(--gutter-width);
        display: grid;
        grid-gap: var(--gutter-width);

        @media (min-width: 640px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .action.captcha-reload {
        @extend %btn;
        @extend .btn-tiny;
        @extend .btn-outline-brand-2;
    }

    .field:has([for="email_address"]),
    .field.note,
    .field.captcha {
        grid-column: span 2;
    }

    legend.legend {
        grid-column: span 2;
        padding: 0;
        border: none;
        margin: 0;

        + br {
            display: none;
        }
    }

    .field {
        margin: 0;

        + .field {
            margin-top: 0;
        }
    }

    .field.choice {
        grid-column: span 2;
    }


    .field.password,
    .field.confirmation {
        grid-column: span 2;
    }

    label {
        text-align: left;
    }

    .additional_info {
        display: none; // This is empty so hide it for now
    }

    .actions-toolbar {
        margin: var(--gutter-width) 0;

        .primary button {
            @extend %btn;
            @extend .btn-thin;
            @extend .btn-brand-2;
        }
    }
}
