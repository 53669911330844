
.themed-radio {
    @extend .font-headline-700;
    position: realtive;
    background-color: var(--color-white-1);
    border: 1px solid var(--color-product-chrome-1);
    padding: 18px var(--gutter-width);
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 150ms ease-in;

    input {
        position: absolute;
        opacity: 0;
    }

    &:before,
    &:after {
        display: none;
    }

    &:hover {
        border-color: var(--color-brand-2);
    }
}

.themed-radio label {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    margin-bottom: 0;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-color: var(--color-white-1);
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 50%;
    }
}

.themed-radio:has(input:checked) {
    background-color: var(--color-cyan-1);
    border-color: var(--color-cyan-1);
    color: var(--color-white-1);

    label:before {
        background-color: var(--color-cyan-1);
        border: 2px solid var(--color-white-1);
        border-radius: 50%;
    }

    label:after {
        content: '';
        position: absolute;
        left: 5px;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: var(--color-white-1);
    }
}
