
.auth-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        @extend .font-headline-700;
        font-size: 20px;
        margin: 1em 0 1em;
        line-height: 1.2;
    }

    a {
        width: 100%;
    }
}
