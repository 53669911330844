.indicator-container {
    position: relative;
}

.indicator {
    position: absolute;
    top: 0;
    right: 0;
    translate: 50% -50%;
    padding: 3px 4px;
    min-width: 10px;
    background: var(--color-brand-2);
    border-radius: 12px;
    font-size: 12px;
    color: var(--color-white-1);
    line-height: 1;

    &.empty {
        display: none;
    }
}

.indicator span {
    display: block;
    translate: 0 -1.5px;
    min-width: 10px;
    text-align: center;
}
