
.products-slider-section {
    overflow: hidden;
    margin-top: 60px;
    padding-bottom: 2px;
    margin-bottom: 60px;

    .container-max {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0; right: 0;
            z-index: 100;
            width: 100vw;
            height: 100%;
            background: rgb(255,255,255);
            background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 99%, rgba(255,255,255,0) 100%);
            transform: translateX(100%);
        }
    }

    @include media('lg') {
        margin-top: 120px;
        margin-bottom: 60px;
    }
}

.products-slider-wrapper .tns-outer {
    margin-top: 20px;

    @include media('md') {
        margin-top: 40px;
    }
}

.products-slider-wrapper {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        z-index: 100;
        width: 100vw;
        height: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 99%, rgba(255,255,255,0) 100%);
        transform: translateX(-100%);
    }

    .tns-ovh {
        overflow: visible;
    }
}

.products-slider-wrapper .titlebar {
    margin-bottom: 0;
}

.products-slider__title {
    @extend .font-headline-700;
    font-size: 26px;

    @include media('sm') {
        font-size: 30px;
    }

    @include media('md') {
        font-size: 36px;
    }

    @include media('lg') {
        font-size: 48px;
    }
}

.brand-product-slider {
    .titlebar {
        .link {
            margin-top: 10px;

            @include media('sm') {
                margin-top: 0px;
            }
        }
    }
    .products-slider__title {
        font-size: 26px;

        @include media('sm') {
            font-size: 30px;
        }
    }
}

.products-slider__description-wrapper {
    position: relative;
    margin-top: 1em;
}

.products-slider__description {
    max-width: 540px;
    font-size: 18px;
    line-height: 30px;

    @include media('md') {
        font-size: 20px;
    }
}

.products-slider-controls {
    position: absolute;
    top: 0; right: 0;
    display: none;

    button {
        cursor: pointer;
        width: 48px;
        height: 48px;
        padding: 0;
        color: red;
        background-color: transparent;
        border: 1px solid var(--color-product-chrome-1);
        border-radius: 48px;
    }

    svg {
        width: 16px;
    }

    @include media('sm') {
        display: block;
    }
}


.products-slider-section--secondary {

    .products-slider__content {
        position: relative;
    }

    .products-slider__title {
        font-size: 32px;

        @include media('xs') {
            font-size: 36px;
        }

        @include media('sm') {
            font-size: 38px;
        }

        @include media('md') {
            font-size: 40px;
        }
    }

    .products-slider__description-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .products-slider-controls {
            position: static;
            display: none;
            align-items: center;
            grid-gap: 10px;

            @include media('sm') {
            }
        }
    }

    .products-slider__description {
        display: none;
    }
}
