
.form-dialog {
    position: relative; 
    z-index: 999;
    padding: 0;
    border: none;
}

.form-dialog .form-section__title {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--color-product-chrome-1);
}

.form-dialog-content {
    width: 540px;
    padding: 30px;
}

.form-dialog__close {
    position: absolute; 
    top: 0;
    right: 0;
    padding: 0;
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    transform: translate(-25%, 25%);
    cursor: pointer;

    svg {
        width: 20px;
    }
}

.form-dialog::backdrop {
    backdrop-filter: blur(5px);
}

.form-dialog__title {
    @extend .font-headline-700;
    text-align: center;
    font-size: 30px;
}

.form-dialog__description {
    text-align: center;
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 1.5em;
}

.form-dialog .form {
}
