.checkout-payment-method
.payment-method._active 
.payment-method-content {
    @media (min-width: 640px) {
        width: calc(200% + var(--gutter-width));
    }
}

.payment-group 
.payment-method:nth-of-type(3) 
.payment-method-content {
    @media (min-width: 640px) {
        translate: calc(-50% - calc(var(--gutter-width) / 2)) 0;
    }
}

.payment-method-billing-address {
    margin-top: var(--gutter-width);
}

.checkout-payment-method .billing-address-same-as-shipping-block {
    margin-bottom: var(--gutter-width);
}

.checkout-payment-method 
.checkout-billing-address 
.billing-address-details {
    --_text-color: var(--text-color, var(--color-grey-2));
    --_background-color: var(--background-color, transparent);
    --_border-color: var(--border-color, var(--color-product-chrome-1));
    --_link-color: var(--link-color, var(--color-brand-2));
    padding: var(--gutter-width);
    border: 1px solid var(--_border-color);
    background-color: var(--_background-color);
    border-radius: 4px;
    color: var(--_text-color);

    a {
        color: var(--_link-color);
    }

    &.selected-item {
        --text-color: var(--color-white-1);
        --background-color: var(--color-brand-2);
        --link-color: var(--color-white-1);
        --border-color: var(--color-brand-2);
    }
}

.checkout-payment-method 
.action.action-edit-address {
    color: inherit;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    border: none;
    margin-top: var(--gutter-width);
    margin-right: var(--gutter-width)
}

// .checkout-payment-method .checkout-billing-address .billing-address-details {
//     // padding-left: 0;
//     // padding: var(--gutter-width);
//     // border: 1px solid var(--color-product-chrome-1);
// }
// 
// .checkout-payment-method .checkout-billing-address {
//     margin-bottom: 0 !important;
// }
// 
// 
// .checkout-billing-address {
//     display: grid;
//     grid-gap: var(--gutter-width);
//     padding-left: 15px;
//     padding-right: 15px;
//     margin-top: var(--gutter-width);
// 
//     @media (min-width: 768px) {
//         padding-left: unset;
//         padding-right: unset;
//     }
// 
//     > * {
//         min-width: 0px;
//     }
// }
// 
