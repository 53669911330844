
.product-site-actions {
    display: flex;
    grid-gap: var(--gutter-width);
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;

    @media (min-width: 640px) {
        grid-gap: unset;
        flex-direction: row;
    }
}
