
.secondary-navigation {
    display: flex;
    align-items: center;

    .showroom-link,
    .menu-link {
        margin-right: 24px;

        @include media('md') {
            margin-right: 44px;
        }
    }

    .showroom-link {
        display: none;

        @include media('md') {
            display: flex;
        }
    }

    .menu-link svg {
        width: 15px;
        margin-right: 12px;
    }

    .icon-link + .icon-link {
        margin-left: 22px;
    }

    .icon-link svg {
        height: 25px;
    }
}
