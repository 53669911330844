
.banner-hero {
    display: flex;
    background-size: cover;
    background-position: center;
    min-height: 260px;

    @include media('sm') {
        min-height: 400px;
    }

    @include media('md') {
        min-height: 450px;
    }
}

.banner-hero__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.28;
    margin-bottom: 0;
    text-wrap: balance;

    @media (min-width: 375px) {
        font-size: 38px;
    }

    @include media('sm') {
        font-size: 40px;
    }

    @include media('md') {
        font-size: 48px;
    }

    @include media('lg') {
        font-size: 56px;
    }
}

.banner-hero__description {
    max-width: 570px;
    font-size: 18px;
    margin-top: 1em;

    @include media('sm') {
        font-size: 20px;
    }
}
