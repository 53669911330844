.header .navigation-outer{
    transition: translate 100ms ease-out;
}

.header.header--hidden .navigation-outer {
    translate: 0 -120%;
}

.navigation-wrapper {
    display: none;
    height: var(--header-height);

    @include media('sm') {
        display: block;
    }
}

.navigation-outer {
    position: fixed;
    z-index: 500;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    padding-left: 36px;
    padding-right: 36px;
    border-bottom: 1px solid var(--color-product-chrome-1);
    background-color: var(--color-white-1);
}
