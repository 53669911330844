
.mega-dialog {
    z-index: 888;
    top: var(--header-height);
    position: fixed;
    width: 100%;
    background-color: var(--color-white-2);
    border: none;
    padding: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

    a {
        color: var(--color-grey-2);
        transition: color 100ms ease-in;
        outline: none;

        &:hover {
            color: var(--color-brand-2);
        }
    }
}

.mega-dialog-container {
    max-width: 1600px;
    padding: 0 36px;
}

.mega-dialog-layout--sidebar .mega-dialog-main {
    padding: 48px 45px;
}

.mega-dialog-main {
    max-height: calc(100vh - var(--header-height));
    padding: 48px 45px 48px 0;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
}

.mega-dialog-main div[data-menu] {
    display: none;
}

.mega-dialog-main div[data-menu].mega-dialog-sidebar__menu--active {
    display: block;
}

.mega-dialog-titlebar {
    display: flex;
    align-items: center;
    align-items: baseline;
    justify-content: space-between;
}

.mega-dialog__title {
    @extend .font-headline-700;
    display: flex;
    align-items: baseline;
    font-size: 30px;

    svg {
        width: 16px;
        margin-left: .5em;
        color: var(--color-cyan-1);
    }
}

.mega-dialog__close {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--color-cyan-1);

    svg {
        width: 16px;
    }

    &:hover,
    &:focus {
        border: none;
        background: transparent;
        cursor: pointer;
        color: var(--color-cyan-1);
    }
}

.mega-dialog-content {
}

.mega-dialog-menus {
    column-count: 4;
    column-gap: 10px;
    margin-top: 24px;
}

.mega-dialog__menu {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
}

.mega-dialog__menu > div {
    display: grid;
}

.mega-dialog__menu-title {
    @extend .font-headline-700;
    font-size: 20px;
    padding: .25em 0;
}

.mega-dialog__menu-item {
    font-size: 14px;
    padding: .2em 0;
}

.mega-dialog-menu-simple {
    columns: 5;
    margin-top: 24px;

    a {
        display: block;
    }
}

.mega-dialog-menu-resources {
    margin-top: 24px;
    columns: 4;
}
